import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';

export const AutocompleteInput = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 6px;
  }
  .MuiFormHelperText-root {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 9px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #757575;
  }
`;
