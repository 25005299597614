import { useRef } from 'react';

export const useErrorRef = () => {
  const errorRef = useRef<Error | null>(null);

  const setError = (error: unknown) => {
    if (Array.isArray(error)) {
      errorRef.current = new Error(
        error
          .map((e) => (e instanceof Error ? e.message : 'Unknown error'))
          .join(', ')
      );
    } else if (error instanceof Error) {
      errorRef.current = new Error(error.message);
    } else {
      errorRef.current = new Error('Unknown error');
    }
  };

  return { errorRef, setError };
};
