import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='15'
    height='15'
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.5 1.25C10.95 1.25 13.75 4.05 13.75 7.5C13.75 10.95 10.95 13.75 7.5 13.75C4.05 13.75 1.25 10.95 1.25 7.5C1.25 4.05 4.05 1.25 7.5 1.25ZM9.75625 8.6C8.84375 8.6 7.00625 9.04375 7.00625 9.93125V12.475C7.16875 12.4937 7.33125 12.5 7.5 12.5C9.61875 12.5 11.425 11.175 12.1562 9.31875C11.5813 8.84375 10.425 8.6 9.75625 8.6ZM6.00625 8.11875C5.0875 8.11875 3.45 8.475 2.79375 9.175C3.31875 10.65 4.50625 11.8062 6.00625 12.275V9.93125C6.00625 9.51875 6.16875 8.8 7.19375 8.25625C6.75625 8.16875 6.3375 8.11875 6.00625 8.11875ZM9.75625 5.2125C9.0875 5.2125 8.54375 5.75 8.55 6.41875C8.55 7.0875 9.0875 7.625 9.75625 7.625C10.425 7.625 10.9625 7.0875 10.9625 6.41875C10.9625 5.75 10.425 5.2125 9.75625 5.2125ZM6.00625 4.225C5.1875 4.225 4.53125 4.88125 4.53125 5.7C4.53125 6.5125 5.19375 7.175 6.00625 7.175C6.81875 7.175 7.48125 6.5125 7.48125 5.7C7.48125 4.8875 6.81875 4.225 6.00625 4.225Z'
      fill='currentColor'
    />
  </svg>,
  'ProviderGroupCircle'
);
