import React from 'react';
import { Box, Grid, GridProps, Typography } from './';
import { alpha, styled } from '@mui/material';

const GridStyled = styled<React.FC<GridProps & { selected?: boolean }>>(Grid)`
  min-height: 80px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.primary.main : theme.palette.grey[400]};
  background-color: ${({ theme, selected }) =>
    selected ? alpha(theme.palette.primary.main, 0.1) : 'transparent'};
  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.1)};
    border-color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.main : 'transparent'};
    cursor: pointer;
  }
`;

export const IngestTemplateCard = ({
  title,
  icon,
  description = 'File Types: xlsx, csv, xls',
  onClick,
  selected,
}: {
  title?: string;
  icon?: React.ReactElement;
  description?: string;
  onClick?: () => void;
  selected?: boolean;
}) => {
  return (
    <GridStyled
      onClick={onClick}
      selected={selected}
      container
      direction='row'
      alignItems='center'
      gap={1}
    >
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        gap='4px'
        alignItems='center'
        textAlign='center'
        color='grey.800'
      >
        <Box display='flex' gap='8px' alignItems='center'>
          {icon}
          <Typography
            lineHeight='normal'
            fontSize={16}
            fontWeight={600}
            variant='h5'
            color='black'
          >
            {title}
          </Typography>
        </Box>
        <Typography fontSize={10} variant='body2'>
          {description}
        </Typography>
      </Box>
    </GridStyled>
  );
};
