import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='18'
    height='15'
    viewBox='0 0 18 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.39691 0.5C5.02072 0.5 1.4604 3.70476 1.4604 7.64286C1.4604 9.01984 1.88976 10.3349 2.70881 11.481L0.666748 14.7857H9.39691C13.7731 14.7857 17.3334 11.581 17.3334 7.64286C17.3334 3.70476 13.7731 0.5 9.39691 0.5Z'
      fill='currentColor'
    />
  </svg>,
  'PackageReportCommentIcon'
);
