import React from 'react';
import {
  TimePicker,
  TimePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';

type TimePickerInputProps = TimePickerProps<Moment>;

export const TimePickerInput = (props: TimePickerInputProps) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={'date-picker-en'}
    >
      <TimePicker {...props} />
    </LocalizationProvider>
  );
};
