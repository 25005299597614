import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='20'
    viewBox='0 0 16 20'
    fill='none'
  >
    <path d='M7.5835 12.9165V15.4165C8.27266 15.4165 8.8335 14.8557 8.8335 14.1665C8.8335 13.4773 8.27266 12.9165 7.5835 12.9165Z' />
    <path d='M3.625 12.9165H3V14.1665H3.625C3.97 14.1665 4.25 13.8865 4.25 13.5415C4.25 13.1965 3.97 12.9165 3.625 12.9165Z' />
    <path d='M11.6783 0.833252H2.16667C1.2475 0.833252 0.5 1.58159 0.5 2.49992V17.4999C0.5 18.4191 1.2475 19.1666 2.16667 19.1666H13.8333C14.7525 19.1666 15.5 18.4191 15.5 17.4999V4.65492L11.6783 0.833252ZM3.625 15.4166H3V16.6666H1.75V11.6666H3.625C4.65833 11.6666 5.5 12.5074 5.5 13.5416C5.5 14.5758 4.65833 15.4166 3.625 15.4166ZM7.58333 16.6666H6.33333V11.6666H7.58333C8.9625 11.6666 10.0833 12.7874 10.0833 14.1666C10.0833 15.5458 8.9625 16.6666 7.58333 16.6666ZM14.5 12.9166H11.75V13.7499H13.8333V14.9999H11.75V16.6666H10.5V12.9166C10.5 12.2274 11.0608 11.6666 11.75 11.6666H14.5V12.9166ZM10.5 5.83325V1.66659L14.6667 5.83325H10.5Z' />
  </svg>,
  'PDFIcon'
);
