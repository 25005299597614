import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.4992 9.16652C17.9058 9.16652 18.2533 8.87236 18.3208 8.47236C18.3883 8.07152 18.1558 7.67986 17.7717 7.54652L2.77166 2.41652C2.44083 2.30236 2.07249 2.40819 1.85249 2.67986C1.63166 2.95069 1.60416 3.33236 1.78499 3.63236L4.86249 8.76152C5.01333 9.01236 5.28416 9.16652 5.57666 9.16652H17.4992Z'
      fill='#BDBDBD'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.57722 10.834C5.28389 10.834 5.01306 10.989 4.86306 11.2382L1.78472 16.369C1.60389 16.6682 1.63222 17.0507 1.85222 17.3223C2.07306 17.5932 2.44056 17.6982 2.77222 17.584L17.7722 12.4548C18.1564 12.3223 18.3889 11.9298 18.3214 11.529C18.2539 11.1273 17.9064 10.834 17.4997 10.834H5.57722Z'
      fill='#BDBDBD'
    />
  </svg>,
  'InviteIcon'
);
