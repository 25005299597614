import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 11V6C15 2.686 12.315 0 9 0C5.685 0 3 2.686 3 6V11C3 12.657 1.656 14 0 14V15H18V14C16.344 14 15 12.657 15 11ZM5.5547 16C6.2477 17.19 7.5237 18 8.9997 18C10.4767 18 11.7517 17.19 12.4457 16H5.5547Z'
      fill='#FF5703'
    />
  </svg>,
  'BellIcon'
);
