import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='12'
    height='13'
    viewBox='0 0 12 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_20047_2262)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 5.5H1.5V4.5H5V5.5ZM4 7H1.5V6H4V7ZM6.5 4.5H8.5V5.5H6.5V4.5ZM9 5.5C9.346 5.5 9.6795 5.549 10 5.6315V3.5H0V7.5C0 8.0515 0.4485 8.5 1 8.5H5.1315C5.576 6.776 7.137 5.5 9 5.5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 1.5C10 0.9485 9.552 0.5 9 0.5H1C0.4485 0.5 0 0.9485 0 1.5V2.5H10V1.5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 9.99219H8.5V7.49219H9.5V8.99219H10.5V9.99219ZM9 6.49219C7.3455 6.49219 6 7.83819 6 9.49219C6 11.1462 7.3455 12.4922 9 12.4922C10.6545 12.4922 12 11.1462 12 9.49219C12 7.83819 10.6545 6.49219 9 6.49219Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_20047_2262'>
        <rect
          width='12'
          height='12'
          fill='white'
          transform='translate(0 0.5)'
        />
      </clipPath>
    </defs>
  </svg>,
  'ConnectorRunning'
);
