import { MRT_RowSelectionState, MRT_SortingState } from 'material-react-table';

const createDeepObject = (keys: string[], value: any) => {
  const [key, ...rest] = keys;
  if (rest.length === 0) {
    return { [key]: value };
  }
  return { [key]: createDeepObject(rest, value) };
};

export const sorting = (sorting: MRT_SortingState) =>
  sorting.map((s) =>
    createDeepObject(s.id.split('.'), s.desc ? 'desc' : 'asc')
  );

export const selection = (selection: MRT_RowSelectionState) =>
  Object.entries(selection)
    .filter(([, v]) => v)
    .map(([k]) => k);
