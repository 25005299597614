import { useCallback, useState } from 'react';
import { ConfirmationModalProps } from '../components';

export type ConfirmationModalConfirm = (
  props: Omit<ConfirmationModalProps, 'open'>
) => void;

export const useConfirmationModal = () => {
  const [modalProps, setModalProps] = useState<ConfirmationModalProps>({
    open: false,
  });

  const confirm = useCallback(
    ({
      title,
      cancelLabel,
      confirmLabel,
      content,
      hideCancel,
      handleCancel,
      handleConfirm,
    }: Omit<ConfirmationModalProps, 'open'>) => {
      setModalProps({
        open: true,
        title,
        content,
        handleCancel: () => {
          setModalProps((state) => ({ ...state, open: false }));
          handleCancel?.();
        },
        handleConfirm: () => {
          setModalProps((state) => ({ ...state, open: false }));
          handleConfirm?.();
        },
        hideCancel,
        cancelLabel,
        confirmLabel,
      });
    },
    []
  );

  return { modalProps, confirm };
};
