import React from 'react';
import { useIsSuspended } from '@cherre-frontend/data-fetching';
import { useDisabledContext } from '@cherre-frontend/core';

//ALWAYS IMPORT ONLY THE COMPONENT YOU NEED FROM MATERIAL
import MuiRadio, { RadioProps } from '@mui/material/Radio';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import styled from '@mui/styled-engine';

const RadioContainer = styled(FormControlLabel)`
  margin-left: 0px;
  .MuiRadio-root {
    padding: 0px;
    margin-right: 6px;
  }
`;

export type CustomRadioProps<T> = RadioProps & {
  label: React.ReactNode;
  value?: T;
  disabled?: boolean;
  testID?: string;
  formControlLabelProps?: Partial<FormControlLabelProps>;
};

const Radio = <T,>({
  testID,
  formControlLabelProps,
  ...props
}: CustomRadioProps<T>) => {
  const suspended = useIsSuspended();
  const [parentDisabled] = useDisabledContext();
  const disabled = suspended || parentDisabled || props.disabled;
  return (
    <RadioContainer
      value={props.value || props.label}
      control={
        <MuiRadio {...props} inputProps={{ 'data-testid': testID } as any} />
      }
      label={props.label}
      disabled={disabled}
      {...formControlLabelProps}
    />
  );
};

(Radio as React.FC).displayName = '@cherre-frontend/Radio';

export default Radio;
