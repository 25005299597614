import React from 'react';
import { useIsSuspended } from '@cherre-frontend/data-fetching';
import { useDisabledContext } from '@cherre-frontend/core';

//ALWAYS IMPORT ONLY THE COMPONENT YOU NEED FROM MATERIAL
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import styled from '@mui/styled-engine';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';

const TextFieldStyled = styled(TextField)`
  .MuiInputBase-input {
    height: 17px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 10px;
  }
  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
  }
  .MuiInputBase-adornedStart {
    padding-left: 10px;
  }
  .input {
    border-radius: 20px;
    gap: 4px;
  }
`;

export type SearchInputProps = TextFieldProps;

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const suspend = useIsSuspended();
  const [parentDisabled] = useDisabledContext();
  const disabled = suspend || parentDisabled || props.disabled;
  return (
    <TextFieldStyled
      variant='outlined'
      size='small'
      placeholder='Search'
      InputProps={{
        startAdornment: <Search fontSize='small' color='disabled' />,
        endAdornment: props.value ? (
          <IconButton
            size='small'
            onClick={() => props.onChange?.({ target: { value: '' } } as any)}
          >
            <Close />
          </IconButton>
        ) : undefined,
        className: 'input',
      }}
      {...props}
      disabled={disabled}
    />
  );
};

SearchInput.displayName = '@cherre-frontend/SearchInput';

export default SearchInput;
