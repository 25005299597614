import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <path
    id='Shape'
    fillRule='evenodd'
    clipRule='evenodd'
    d='M11.25 0H2.25C1.0125 0 0.0112503 0.99 0.0112503 2.2L0 19.8C0 21.01 1.00125 22 2.23875 22H15.75C16.9875 22 18 21.01 18 19.8V6.6L11.25 0ZM13.5 17.6H4.5V15.4H13.5V17.6ZM13.5 13.2H4.5V11H13.5V13.2ZM10.125 7.7V1.65L16.3125 7.7H10.125Z'
    fill='currentColor'
  />,
  'FileIcon'
);
