import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5001 1.66663H11.6667C11.2067 1.66663 10.8334 2.03996 10.8334 2.49996V3.33329H16.6667V4.99996H10.8334V6.66663H15.8334V8.33329H10.8334V9.99996H15.0001V11.6666H10.8334V17.5C10.8334 17.9608 11.2067 18.3333 11.6667 18.3333H13.3334V15.8333H15.8334V18.3333H17.5001C17.9601 18.3333 18.3334 17.9608 18.3334 17.5V2.49996C18.3334 2.03996 17.9601 1.66663 17.5001 1.66663ZM8.33342 6.66663H2.50008C2.04008 6.66663 1.66675 7.03996 1.66675 7.49996V8.33329H6.66675V9.99996H1.66675V11.6666H5.00008V13.3333H1.66675V17.5C1.66675 17.9608 2.04008 18.3333 2.50008 18.3333H4.16675V15.8333H6.66675V18.3333H8.33342C8.79342 18.3333 9.16675 17.9608 9.16675 17.5V7.49996C9.16675 7.03996 8.79342 6.66663 8.33342 6.66663Z'
      fill='currentColor'
    />
  </svg>,
  'Properties'
);
