import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_44799_130214)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99951 13C10.3364 13 10.6659 13.0126 10.9876 13.0373C9.74646 14.5149 8.99951 16.4202 8.99951 18.5C8.99951 20.153 9.47135 21.6958 10.2877 23.001L1.99951 23V19C1.99951 15.467 5.28951 13 9.99951 13Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 20C15.895 20 15 19.104 15 18C15 16.896 15.895 16 17 16C18.104 16 19 16.896 19 18C19 19.104 18.104 20 17 20ZM23.002 19V17H21.9C21.77 16.362 21.515 15.771 21.167 15.246L21.951 14.462L20.537 13.048L19.752 13.832C19.229 13.486 18.638 13.23 18.001 13.102V12H16.001V13.102C15.364 13.23 14.772 13.486 14.248 13.833L13.465 13.05L12.051 14.464L12.834 15.247C12.486 15.771 12.232 16.363 12.102 17H11V19H12.102C12.232 19.638 12.486 20.229 12.834 20.754L12.051 21.538L13.466 22.951L14.249 22.167C14.773 22.515 15.365 22.77 16.002 22.899V24.001H18.002V22.898C18.639 22.769 19.23 22.514 19.755 22.166L20.539 22.949L21.952 21.535L21.168 20.752C21.516 20.228 21.77 19.637 21.9 19H23.002Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 10C8.346 10 7 8.654 7 7H13C13 8.654 11.654 10 10 10ZM10 2C7.238 2 5 4.238 5 7C5 9.762 7.238 12 10 12C12.762 12 15 9.762 15 7C15 4.238 12.762 2 10 2Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_44799_130214'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Admin'
);
