export { default as Dialog, DialogProps } from '@mui/material/Dialog';
export {
  DialogContentProps,
  default as DialogContent,
} from '@mui/material/DialogContent/DialogContent';
export {
  default as DialogActions,
  DialogActionsProps,
} from '@mui/material/DialogActions/DialogActions';
export {
  default as DialogTitle,
  DialogTitleProps,
} from '@mui/material/DialogTitle/DialogTitle';
