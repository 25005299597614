import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    width='16'
    height='16'
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 4.3125C8.31 4.3125 7.75 3.75312 7.75 3.0625C7.75 2.3725 8.31 1.8125 9 1.8125C9.69 1.8125 10.25 2.3725 10.25 3.0625C10.25 3.75312 9.69 4.3125 9 4.3125ZM9.9375 0.25H3.0625C1.51188 0.25 0.25 1.51188 0.25 3.0625C0.25 4.61375 1.51188 5.875 3.0625 5.875H9.9375C11.4881 5.875 12.75 4.61375 12.75 3.0625C12.75 1.51188 11.4881 0.25 9.9375 0.25ZM4 11.1875C3.31 11.1875 2.75 10.6281 2.75 9.9375C2.75 9.2475 3.31 8.6875 4 8.6875C4.69 8.6875 5.25 9.2475 5.25 9.9375C5.25 10.6281 4.69 11.1875 4 11.1875ZM9.9375 7.125H3.0625C1.51188 7.125 0.25 8.38688 0.25 9.9375C0.25 11.4888 1.51188 12.75 3.0625 12.75H9.9375C11.4881 12.75 12.75 11.4888 12.75 9.9375C12.75 8.38688 11.4881 7.125 9.9375 7.125Z'
      fill='currentColor'
    />
  </svg>,
  'TogglesIcon'
);
