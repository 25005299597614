import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Group'>
      <path
        id='Vector'
        d='M2.33268 4.0013H0.666016V15.668C0.666016 16.5846 1.41602 17.3346 2.33268 17.3346H13.9993V15.668H2.33268V4.0013ZM10.666 8.16797C12.0493 8.16797 13.166 7.0513 13.166 5.66797C13.166 4.28464 12.0493 3.16797 10.666 3.16797C9.28268 3.16797 8.16602 4.28464 8.16602 5.66797C8.16602 7.0513 9.28268 8.16797 10.666 8.16797ZM10.666 4.83464C11.1243 4.83464 11.4993 5.20964 11.4993 5.66797C11.4993 6.1263 11.1243 6.5013 10.666 6.5013C10.2077 6.5013 9.83268 6.1263 9.83268 5.66797C9.83268 5.20964 10.2077 4.83464 10.666 4.83464ZM15.666 0.667969H5.66602C4.74935 0.667969 3.99935 1.41797 3.99935 2.33464V12.3346C3.99935 13.2513 4.74935 14.0013 5.66602 14.0013H15.666C16.5827 14.0013 17.3327 13.2513 17.3327 12.3346V2.33464C17.3327 1.41797 16.5827 0.667969 15.666 0.667969ZM7.90768 12.3346C8.69935 11.8096 9.64935 11.5013 10.666 11.5013C11.6827 11.5013 12.6327 11.8096 13.4243 12.3346H7.90768ZM15.666 12.1096C14.441 10.718 12.666 9.83464 10.666 9.83464C8.66602 9.83464 6.89102 10.718 5.66602 12.1096V2.33464H15.666V12.1096Z'
        fill='currentColor'
      />
    </g>
  </svg>,
  'SwitchAccount'
);
