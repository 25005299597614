import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    width='20'
    height='18'
    viewBox='0 0 20 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 14V12.95C13.14 12.717 14 11.708 14 10.5C14 9.122 12.878 8 11.5 8H10.5C10.224 8 10 7.776 10 7.5C10 7.224 10.224 7 10.5 7H14V5H12V4H10V5.05C8.86 5.283 8 6.292 8 7.5C8 8.878 9.122 10 10.5 10H11.5C11.776 10 12 10.224 12 10.5C12 10.776 11.776 11 11.5 11H8V13H10V14H12Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 0C6.038 0 2 4.038 2 9H0L3 13L6 9H4C4 5.14 7.14 2 11 2C14.86 2 18 5.14 18 9C18 12.86 14.86 16 11 16V18C15.962 18 20 13.962 20 9C20 4.038 15.962 0 11 0Z'
      fill='white'
    />
  </svg>,
  'UpgradePlanIcon'
);
