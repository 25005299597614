import React, { useRef, useState } from 'react';
import { RecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  CherreValue,
  isScopedNode,
  useIsSuspended,
  useRecoilScope,
} from '@cherre-frontend/data-fetching';
import { useDisabledContext } from '@cherre-frontend/core';

//ALWAYS IMPORT ONLY THE COMPONENT YOU NEED FROM MATERIAL
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';

export {
  default as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';

import CircularProgress from '@mui/material/CircularProgress';
import { AutocompleteInput } from './AutocompleteInput';
import Chip from './Chip';

type Selector<T> = CherreValue<T[] | ReadonlyArray<T>>;
type SearchInput = string | null;
type SelectorFamily<T> = (input: SearchInput) => Selector<T>;

export interface AutocompleteProps<Value, Multiple extends boolean | undefined>
  extends Omit<
    MuiAutocompleteProps<Value, Multiple, false, false>,
    'renderInput' | 'options' | 'value' | 'isOptionEqualToValue'
  > {
  testID?: string;
  placeholder?: string;
  label?: string;
  getSelectedOption: (option: Value) => boolean;
  selector: SelectorFamily<Value> | Selector<Value>;
  maxVisibleOptions?: number;
  helperText?: string;
  variant?: 'standard' | 'outlined' | 'filled';
}

const Autocomplete = <Value, Multiple extends boolean | undefined = undefined>({
  selector,
  getSelectedOption,
  maxVisibleOptions = 6,
  helperText,
  testID,
  label,
  ...props
}: AutocompleteProps<Value, Multiple>) => {
  const suspended = useIsSuspended();
  const [parentDisabled] = useDisabledContext();
  const disabled = suspended || parentDisabled || props.disabled;
  const [inputValue, setInputValue] = useState<string | null>(null);
  const scope = useRecoilScope();
  let _selector: RecoilValue<Value[] | ReadonlyArray<Value>>;
  if (isScopedNode(selector)) {
    _selector = selector(scope);
  } else if (typeof selector === 'function') {
    const _temp = selector(inputValue);
    if (isScopedNode(_temp)) {
      _selector = _temp(scope);
    } else {
      _selector = _temp;
    }
  } else {
    _selector = selector;
  }
  const loadable = useRecoilValueLoadable(_selector);
  if (loadable.state === 'hasError') {
    throw loadable.contents;
  }
  const loading = loadable.state === 'loading';
  const optionsFresh = loadable.valueMaybe();
  const optionsRef = useRef<ReadonlyArray<Value>>([]);
  if (optionsFresh) {
    optionsRef.current = optionsFresh;
  }
  const options = optionsFresh || optionsRef.current;
  return (
    <MuiAutocomplete
      filterOptions={typeof selector === 'function' ? (x) => x : undefined}
      {...props}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            size='small'
            label={props.getOptionLabel?.(option) ?? option}
            {...getTagProps({ index })}
            sx={{ margin: '0px !important' }}
          />
        ))
      }
      renderInput={(params) => (
        <AutocompleteInput
          {...params}
          label={label}
          placeholder={props.placeholder}
          size='small'
          variant={props.variant}
          helperText={helperText}
          inputProps={{ 'data-testid': testID, ...params.inputProps }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
              </React.Fragment>
            ),
          }}
        />
      )}
      options={options}
      onInputChange={(_, value) => setInputValue(value || null)}
      freeSolo={false}
      disableClearable={false}
      value={
        props.multiple
          ? options.filter(getSelectedOption)
          : ((options.find(getSelectedOption) || null) as any)
      }
      ListboxProps={{ sx: { maxHeight: `${16 + maxVisibleOptions * 33}px` } }}
      disabled={disabled}
    />
  );
};

export type AutocompleteComponent = typeof Autocomplete;

(Autocomplete as React.FC).displayName = '@cherre-frontend/Autocomplete';

export default Autocomplete;
