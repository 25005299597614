import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 15.667H7V13.667H9V10.667H8V8.66699H10C10.552 8.66699 11 9.11499 11 9.66699V13.667H13V15.667ZM10 5.41699C10.69 5.41699 11.25 5.97699 11.25 6.66699C11.25 7.35699 10.69 7.91699 10 7.91699C9.31 7.91699 8.75 7.35699 8.75 6.66699C8.75 5.97699 9.31 5.41699 10 5.41699ZM10 0.666992C4.486 0.666992 0 5.15299 0 10.667C0 16.182 4.486 20.667 10 20.667C15.514 20.667 20 16.182 20 10.667C20 5.15299 15.514 0.666992 10 0.666992Z'
      fill='inherit'
    />
  </svg>,
  'InfoAltIcon'
);
