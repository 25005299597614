import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.6665 5.66672H11.9882L10.0773 7.57756L11.2557 8.75589L14.589 5.42255C14.9148 5.09672 14.9148 4.57006 14.589 4.24422L11.2557 0.910889L10.0773 2.08922L11.9882 4.00006H5.6665C2.909 4.00006 0.666504 6.24256 0.666504 9.00006H2.33317C2.33317 7.16172 3.82817 5.66672 5.6665 5.66672ZM15.6665 9.00006C15.6665 10.8384 14.1715 12.3334 12.3332 12.3334H6.0115L7.92234 10.4226L6.744 9.24422L3.41067 12.5776C3.08484 12.9034 3.08484 13.4301 3.41067 13.7559L6.744 17.0892L7.92234 15.9109L6.0115 14.0001H12.3332C15.0907 14.0001 17.3332 11.7576 17.3332 9.00006H15.6665Z'
      fill='#9E9E9E'
    />
  </svg>,
  'DisabledSmallReplaceIcon'
);
