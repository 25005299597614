import * as React from 'react';
import MuiTabs, { TabsProps as MuiTabProps } from '@mui/material/Tabs';
import MuiTab, { TabOwnProps } from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styled from '@mui/styled-engine';

export { default as MuiTabs } from '@mui/material/Tabs';

type Value = TabOwnProps['value'];

interface TabPanelProps {
  children?: React.ReactNode;
  value: Value;
  currentValue?: Value;
}

type Tab = TabOwnProps & {
  value: Value;
};

type TabPanel = { panel: React.ReactNode } & {
  value: Value;
};

export interface TabsProps {
  tabs: Tab[];
  tabPanels: TabPanel[];
  value: Value;
  onChange: (event: React.SyntheticEvent, newValue: Value) => void;
  disabledIcon?: React.ReactElement;
  actions?: React.ReactNode;
  showBorder?: boolean;
  muiTabsProps?: MuiTabProps;
}

function TabPanel({ currentValue, value, children }: TabPanelProps) {
  return <>{currentValue === value && children}</>;
}

const Tab = styled(MuiTab)`
  min-height: 48px;

  & > .MuiTab-iconWrapper {
    width: 18px;
    height: 18px;
    margin-left: 6px;
    margin-right: 6px;
  }
`;

export default function Tabs({
  tabs,
  actions,
  tabPanels,
  value: currentValue,
  onChange,
  disabledIcon,
  showBorder = true,
  muiTabsProps,
}: TabsProps) {
  return (
    <Box data-testid='tabs' sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: showBorder ? 1 : undefined,
          borderColor: showBorder ? 'divider' : undefined,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MuiTabs {...muiTabsProps} value={currentValue} onChange={onChange}>
          {tabs.map(({ icon, iconPosition, ...tabProps }) => (
            <Tab
              {...tabProps}
              iconPosition={iconPosition ?? 'end'}
              icon={(tabProps.disabled && disabledIcon) || icon}
              key={`tab-${tabProps.value}`}
            />
          ))}
        </MuiTabs>
        {actions && <Box>{actions}</Box>}
      </Box>
      {tabPanels.map(({ value: tabValue, panel }) => (
        <TabPanel
          currentValue={currentValue}
          key={`tabpanel-${tabValue}`}
          value={tabValue}
        >
          {panel}
        </TabPanel>
      ))}
    </Box>
  );
}
