import React from 'react';
import styled from '@mui/system/styled';
import { Card, Grid } from '@mui/material';

type ValueProps = {
  valueColor?: string;
};

type LabelProps = {
  labelColor?: string;
};

const CardStyles = styled(Card, {
  shouldForwardProp: (prop) => !['labelColor'].includes(prop as string),
})<{ labelColor?: string }>`
  border-radius: 20px;
  padding: 16px 20px;
  height: 100%;

  svg {
    color: ${({ theme, labelColor }) =>
      labelColor ? labelColor : theme.palette.grey[700]};
    height: 20px;
    width: 20px;
  }

  .MuiPaper-root {
    height: 100%;
  }

  h3 > * {
    display: none;
  }
`;

const ValueWrapper = styled('div')`
  display: flex;
  align-items: flex-end;
  line-height: 1;
  font-weight: 700;
  gap: 4px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const Label = styled('p', {
  shouldForwardProp: (prop) => !['labelColor'].includes(prop as string),
})<LabelProps>`
  margin: 0;
  color: ${({ theme, labelColor }) =>
    labelColor ? labelColor : theme.palette.grey[700]};
`;

const Value = styled('h3', {
  shouldForwardProp: (prop) => !['valueColor'].includes(prop as string),
})<ValueProps>`
  margin: 0;
  font-size: 30px;
  font-weight: inherit;
  color: ${({ valueColor }) => (valueColor ? valueColor : 'inherit')};
`;

const ValueDetail = styled('p')`
  margin: 0;
  font-size: 20px;
  font-weight: inherit;
  padding-bottom: 2px;
`;

export type CardKPIProps = {
  icon?: React.ReactElement;
  label: string;
  value: string | number;
  valueDetail?: string;
  valueColor?: string;
  labelColor?: string;
};

const CardKPI: React.FC<CardKPIProps> = ({
  label,
  icon,
  value,
  valueDetail,
  valueColor,
  labelColor,
}) => {
  return (
    <CardStyles labelColor={labelColor}>
      <ValueWrapper>
        <Value
          className='suspend'
          valueColor={Number(value) > 0 ? valueColor : 'inherit'}
        >
          {value}
        </Value>
        {valueDetail && (
          <ValueDetail className='suspend'>{valueDetail}</ValueDetail>
        )}
      </ValueWrapper>
      <Grid
        container
        direction='row'
        style={{ gap: 5, flexWrap: 'nowrap', alignItems: 'center' }}
      >
        {icon}
        <Label labelColor={labelColor}>{label}</Label>
      </Grid>
    </CardStyles>
  );
};

export default CardKPI;
