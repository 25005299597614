import styled from '@mui/styled-engine';

export const SuspenseWrapper = styled('div')`
  @keyframes shine {
    to {
      background-position: 100% 0;
    }
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  * .suspend {
    display: inline-flex;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      lightgray;
    background-repeat: repeat-y;
    background-size: 100vh 100vw;
    background-position: 0 0;
    animation: shine 1s infinite;
    min-height: 1.2em;
    min-width: 2.4em;
    color: transparent;
    * {
      display: none;
    }
  }
`;

export const ResumeWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
