import React, { useEffect, useRef, useState } from 'react';
import { useIsSuspended } from '@cherre-frontend/data-fetching';
import { useDisabledContext } from '@cherre-frontend/core';
import { useRect } from '../hooks/useRect';

//ALWAYS IMPORT ONLY THE COMPONENT YOU NEED FROM MATERIAL
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export type ButtonProps = Omit<Parameters<typeof MuiButton>[0], 'onClick'> & {
  testID?: string;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void | Promise<void>;
};

const Button: React.FC<ButtonProps> = React.forwardRef(
  ({ testID, ...props }, ref) => {
    const suspended = useIsSuspended();
    const [parentDisabled] = useDisabledContext();
    const [loading, setLoading] = useState(false);
    const disabled = suspended || loading || parentDisabled || props.disabled;
    const [rect, contentRef] = useRect(loading);
    const mounted = useRef(false);

    useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        console.warn(`Button is disabled`);
        return;
      }
      const onClickResult = props.onClick?.(event);
      if (onClickResult instanceof Promise) {
        setLoading(true);
        onClickResult.finally(() => mounted.current && setLoading(false));
      }
    };
    return (
      <MuiButton
        {...props}
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        startIcon={loading ? undefined : props.startIcon}
        endIcon={loading ? undefined : props.endIcon}
        data-testid={testID}
        sx={{ borderRadius: '6px', ...props.sx }}
      >
        {loading ? (
          <span
            style={{
              width: rect?.width,
              height: rect?.height,
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <CircularProgress size={20} />
          </span>
        ) : (
          <span ref={contentRef}>{props.children}</span>
        )}
      </MuiButton>
    );
  }
);

Button.displayName = '@cherre-frontend/Button';

export default Button;
