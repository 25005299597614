import React from 'react';

//ALWAYS IMPORT ONLY THE COMPONENT YOU NEED FROM MATERIAL
import FilterList from '@mui/icons-material/FilterList';
import Chip, { ChipProps } from '@mui/material/Chip';

export type FilterChipProps = {
  label: string;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onDelete?: () => void;
} & ChipProps;

const FilterChip: React.FC<FilterChipProps> = ({
  label,
  active,
  onClick,
  onDelete,
  ...props
}) => {
  return (
    <Chip
      label={label}
      icon={!active ? <FilterList /> : undefined}
      onClick={onClick}
      onDelete={active ? onDelete ?? onClick : undefined}
      {...props}
    />
  );
};

FilterChip.displayName = '@cherre-frontend/FilterChip';

export default FilterChip;
