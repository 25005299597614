import React from 'react';

const SwitchCTX = React.createContext<any>(null);

const Switch: React.FC<{ value: any }> = ({ value, children }) => {
  return <SwitchCTX.Provider value={value}>{children}</SwitchCTX.Provider>;
};

const Case: React.FC<{ value: any }> = ({ value, children }) => {
  const currentValue = React.useContext(SwitchCTX);
  return currentValue === value ? <>{children}</> : null;
};

export { Switch, Case };
