import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_29261_3646)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0002 5.83333C17.7577 5.83333 20.0002 8.07667 20.0002 10.8333C20.0002 12.5758 19.1027 14.1108 17.7477 15.0067L18.3252 19.0483C18.3719 19.3742 18.2227 19.6967 17.9444 19.8717C17.8086 19.9575 17.6544 20 17.5002 20C17.3394 20 17.1777 19.9533 17.0377 19.86L15.0002 18.5017L12.9627 19.86C12.6886 20.0425 12.3344 20.0467 12.0561 19.8717C11.7777 19.6967 11.6286 19.3742 11.6752 19.0483L12.2527 15.0067C10.8977 14.1108 10.0002 12.5758 10.0002 10.8333C10.0002 8.07667 12.2427 5.83333 15.0002 5.83333ZM15.0002 7.5C13.1594 7.5 11.6669 8.9925 11.6669 10.8333C11.6669 12.6742 13.1594 14.1667 15.0002 14.1667C16.8411 14.1667 18.3336 12.6742 18.3336 10.8333C18.3336 8.9925 16.8411 7.5 15.0002 7.5ZM15.0005 0C15.9197 0 16.6672 0.7475 16.6672 1.66667V4.385C16.133 4.24833 15.5772 4.16667 15.0005 4.16667V1.66667H1.66715V11.6667H8.39132C8.46382 12.2492 8.60965 12.8083 8.82299 13.3333H1.66715C0.747988 13.3333 0.000488281 12.5858 0.000488281 11.6667V1.66667C0.000488281 0.7475 0.747988 0 1.66715 0H15.0005ZM15.0002 9.16667C15.9202 9.16667 16.6669 9.91333 16.6669 10.8333C16.6669 11.755 15.9202 12.5 15.0002 12.5C14.0794 12.5 13.3336 11.755 13.3336 10.8333C13.3336 9.91333 14.0794 9.16667 15.0002 9.16667ZM13.3336 2.50025C13.7936 2.50025 14.1669 2.87275 14.1669 3.33358V4.22442C10.8794 4.63525 8.33357 7.43442 8.33357 10.8336H3.33357C2.87357 10.8336 2.50024 10.4594 2.50024 10.0002V3.33358C2.50024 2.87275 2.87357 2.50025 3.33357 2.50025H13.3336Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_29261_3646'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Certificate'
);
