import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6458 6.24219L10.4036 0H2.08073L1.92609 0.00573523C0.854066 0.0855506 0.0104036 0.988346 0.0104036 2.08073L0 18.7266L0.00567272 18.8813C0.0846263 19.9541 0.977942 20.8073 2.07032 20.8073L10.4036 20.8085V17.6862C10.4036 14.8133 12.7326 12.4844 15.6055 12.4844C15.9622 12.4844 16.3105 12.5203 16.6471 12.5887L16.6458 6.24219ZM9.36328 7.28255V1.56055L15.0853 7.28255H9.36328ZM18.8261 15.1835C18.0801 14.4335 17.0795 14.0095 16.0002 14.0095C13.7942 14.0095 12.0002 15.8042 12.0002 18.0094H13.3336C13.3336 16.5388 14.5295 15.3428 16.0002 15.3428C16.7188 15.3428 17.3768 15.6375 17.8741 16.1361L16.6668 17.3428H20.0001V14.0095L18.8261 15.1835ZM15.9999 20.6762C15.2819 20.6762 14.6233 20.3829 14.126 19.8836L15.3333 18.6763H12V22.0095L13.176 20.8336C13.9213 21.5762 14.9393 22.0095 15.9999 22.0095C18.2059 22.0095 19.9998 20.2156 19.9998 18.0096H18.6665C18.6665 19.4796 17.4706 20.6762 15.9999 20.6762Z'
      fill='currentColor'
    />
  </svg>,
  'FileLoading'
);
