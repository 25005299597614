import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 4C5.552 4 6 4.448 6 5C6 5.552 5.552 6 5 6C4.448 6 4 5.552 4 5C4 4.448 4.448 4 5 4ZM8 4C8.552 4 9 4.448 9 5C9 5.552 8.552 6 8 6C7.448 6 7 5.552 7 5C7 4.448 7.448 4 8 4ZM22 7.002V5C22 3.896 21.103 3 20 3H4C2.897 3 2 3.896 2 5V7.002H22ZM19 13.003H16V11.003H19V13.003ZM19 16.003H15V14.003H19V16.003ZM9 14.003V11.003C10.656 11.003 12 12.347 12 14.003H9ZM8 18.003C6.344 18.003 5 16.66 5 15.003C5 13.347 6.344 12.003 8 12.003V15.003H11C11 16.66 9.656 18.003 8 18.003ZM2 8.002V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V8.002H2Z'
      fill='currentColor'
    />
  </svg>,
  'Dashboard'
);
