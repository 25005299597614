import React from 'react';

//ALWAYS IMPORT ONLY THE COMPONENT YOU NEED FROM MATERIAL
import styled from '@mui/styled-engine';
import Paper, { PaperProps } from '@mui/material/Paper';

const PaperStyled = styled(Paper)`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  flex: 1;
`;

export type PageContentProps = PaperProps & {
  children: React.ReactNode;
};

export const PageContent: React.FC<PageContentProps> = ({
  children,
  ...props
}) => {
  return (
    <PaperStyled elevation={0} {...props}>
      {children}
    </PaperStyled>
  );
};

PageContent.displayName = '@cherre-frontend/PageContent';
