import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg
    width='36'
    height='37'
    viewBox='0 0 36 37'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Data Connection'>
      <path
        id='Combined Shape'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.4988 6.54375C28.3813 7.74125 25.2437 9.375 18.75 9.375C12.2562 9.375 9.11875 7.74125 8.00125 6.54375C9.495 4.295 14.1412 3.125 18.75 3.125C23.3587 3.125 28.005 4.295 29.4988 6.54375ZM7.5 9.33498C9.76125 10.8212 13.5312 11.875 18.75 11.875C23.9688 11.875 27.7388 10.8212 30 9.33498V11.875C30 12.8362 27.23 15.625 18.75 15.625C10.27 15.625 7.5 12.8362 7.5 11.875V9.33498ZM30 18.1249C30 19.0862 27.23 21.8749 18.75 21.8749C10.27 21.8749 7.5 19.0862 7.5 18.1249V15.5849C9.76125 17.0712 13.5312 18.1249 18.75 18.1249C23.9688 18.1249 27.7388 17.0712 30 15.5849V18.1249ZM18.75 24.375C13.5312 24.375 9.76125 23.3213 7.5 21.835V23.125C7.5 26.1625 12.3462 27.8563 17.5 28.0938V30.625H11.25V33.125H26.25V30.625H20V28.0938C25.1537 27.8563 30 26.1625 30 23.125V21.835C27.7388 23.3213 23.9688 24.375 18.75 24.375ZM30 31.875C30 32.565 29.44 33.125 28.75 33.125C28.06 33.125 27.5 32.565 27.5 31.875C27.5 31.185 28.06 30.625 28.75 30.625C29.44 30.625 30 31.185 30 31.875ZM8.75 33.125C9.44 33.125 10 32.565 10 31.875C10 31.185 9.44 30.625 8.75 30.625C8.06 30.625 7.5 31.185 7.5 31.875C7.5 32.565 8.06 33.125 8.75 33.125Z'
        fill='#BDBDBD'
      />
    </g>
  </svg>,
  'DataConnection'
);
