import React from 'react';
import FilterChip from './FilterChip';
import { Menu, MenuItem } from '..';

export type FilterOption<T = any> = {
  label: string;
  value: T;
  default?: true;
};

export type FilterProps<T> = {
  label?: string;
  options: FilterOption<T>[];
  selected: FilterOption<T> | undefined;
  onChange: (option: FilterOption<T> | undefined) => void;
  disabled?: boolean;
};

const Filter = <T,>({
  label,
  options,
  selected,
  onChange,
  disabled,
}: FilterProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleStatusFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusFilterClose = () => {
    setAnchorEl(null);
  };

  const handleStatusFilterChange = (option: FilterOption | undefined) => () => {
    onChange(option);
    handleStatusFilterClose();
  };

  const defaultOption = options.find((option) => option.default);
  const otherOptions = options.filter((option) => !option.default);
  const active = defaultOption
    ? selected?.value === defaultOption.value
    : Boolean(selected);
  return (
    <>
      <FilterChip
        label={selected?.label || label || ''}
        onClick={handleStatusFilterClick}
        active={active}
        onDelete={handleStatusFilterChange(defaultOption)}
        disabled={options.length === 0 || disabled}
      />
      <Menu
        id='more-items-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleStatusFilterClose}
        data-testid='more-items-menu'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {defaultOption ? (
          <MenuItem
            style={{ width: '200px', gap: '6px' }}
            onClick={handleStatusFilterChange(defaultOption)}
          >
            {defaultOption.label}
          </MenuItem>
        ) : null}
        {otherOptions.map((option) => (
          <MenuItem
            key={option.label}
            style={{ width: '200px', gap: '6px' }}
            onClick={handleStatusFilterChange(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

Filter.displayName = '@cherre-frontend/ui/Filter';

export default Filter;
