import React from 'react';
import { ExtendAppContext, ExtendAppContextProps } from '@cherre-frontend/core';
import { DataFetcher, DataFetcherProps } from '@cherre-frontend/data-fetching';

export type PanelProps = ExtendAppContextProps & DataFetcherProps;

export const Panel: React.FC<PanelProps> = ({ children, ...props }) => {
  return (
    <ExtendAppContext {...props}>
      <DataFetcher {...props}>{children}</DataFetcher>
    </ExtendAppContext>
  );
};

Panel.displayName = '@cherre-frontend/Panel';
