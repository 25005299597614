import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.66667 4H2.66667C2.29867 4 2 4.29867 2 4.66667V7.33333C2 7.70133 2.29867 8 2.66667 8H6.66667C7.03467 8 7.33333 7.70133 7.33333 7.33333V4.66667C7.33333 4.29867 7.03467 4 6.66667 4ZM6 12H3.33333V10.6667H6V12ZM6.66667 9.33333H2.66667C2.29867 9.33333 2 9.632 2 10V12.6667C2 13.0347 2.29867 13.3333 2.66667 13.3333H6.66667C7.03467 13.3333 7.33333 13.0347 7.33333 12.6667V10C7.33333 9.632 7.03467 9.33333 6.66667 9.33333ZM6 17.3333H3.33333V16H6V17.3333ZM6.66667 14.6667H2.66667C2.29867 14.6667 2 14.9653 2 15.3333V18C2 18.368 2.29867 18.6667 2.66667 18.6667H6.66667C7.03467 18.6667 7.33333 18.368 7.33333 18V15.3333C7.33333 14.9653 7.03467 14.6667 6.66667 14.6667ZM13.3333 4H9.33333C8.96533 4 8.66667 4.29867 8.66667 4.66667V7.33333C8.66667 7.70133 8.96533 8 9.33333 8H13.3333C13.7013 8 14 7.70133 14 7.33333V4.66667C14 4.29867 13.7013 4 13.3333 4ZM12.6667 12H10V10.6667H12.6667V12ZM13.3333 9.33333H9.33333C8.96533 9.33333 8.66667 9.632 8.66667 10V12.6667C8.66667 13.0347 8.96533 13.3333 9.33333 13.3333H13.3333C13.7013 13.3333 14 13.0347 14 12.6667V10C14 9.632 13.7013 9.33333 13.3333 9.33333ZM12.6667 17.3333H10V16H12.6667V17.3333ZM13.3333 14.6667H9.33333C8.96533 14.6667 8.66667 14.9653 8.66667 15.3333V18C8.66667 18.368 8.96533 18.6667 9.33333 18.6667H13.3333C13.7013 18.6667 14 18.368 14 18V15.3333C14 14.9653 13.7013 14.6667 13.3333 14.6667ZM20 4H16C15.632 4 15.3333 4.29867 15.3333 4.66667V7.33333C15.3333 7.70133 15.632 8 16 8H20C20.368 8 20.6667 7.70133 20.6667 7.33333V4.66667C20.6667 4.29867 20.368 4 20 4ZM19.3333 12H16.6667V10.6667H19.3333V12ZM20 9.33333H16C15.632 9.33333 15.3333 9.632 15.3333 10V12.6667C15.3333 13.0347 15.632 13.3333 16 13.3333H20C20.368 13.3333 20.6667 13.0347 20.6667 12.6667V10C20.6667 9.632 20.368 9.33333 20 9.33333ZM22 17.1667H19.5V14.6667H17.8333V17.1667H15.3333V18.8333H17.8333V21.3333H19.5V18.8333H22V17.1667Z'
      fill='currentColor'
    />
  </svg>,
  'DataGridIcon'
);
