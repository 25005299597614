import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Expand'>
      <path
        id='Combined-Shape'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4V6H6V18H18V12H20V18C20 19.103 19.103 20 18 20H6C4.897 20 4 19.103 4 18V6C4 4.897 4.897 4 6 4H12ZM20 4V10H18V7.414L12.707 12.707L11.293 11.293L16.586 6H14V4H20Z'
        fill='#DF2467'
      />
    </g>
  </svg>,
  'ExpandIcon'
);
