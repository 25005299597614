export type { SvgIconComponent } from '@mui/icons-material';

//MAKE OUR PACKAGE TREE SHAKEABLE
//All icons should end with "Icon" to avoid confusion
//for example: "Report" might be mistakenly import as a full-blown Report component
export { default as AdminIcon } from './AdminIcon';
export { default as CheckIcon } from './Check';
export { default as ChevronRightIcon } from './ChevronRight';
export { default as InfoIcon } from './Info';
export { default as MoreVertIcon } from './MoreVert';
export { default as MRIIcon } from './MRIIcon';
export { default as RefreshIcon } from './Refresh';
export { default as ReportIcon } from './Report';
export { default as GroupIcon } from './GroupIcon';
export { default as PDFIcon } from './PDFIcon';
export { default as PPTICon } from './PPTIcon';
export { default as CertificateIcon } from './Certificate';
export { default as CloseIcon } from './Close';
export { default as CloudUploadIcon } from './CloudUpload';
export { default as DataSettingsIcon } from './DataSettings';
export { default as DashboardIcon } from './DashboardIcon';
export { KeyboardArrowLeft, KeyboardArrowRight } from './KeyboardArrow';
export { default as FileFailedIcon } from './FileFailed';
export { default as FileLoadingIcon } from './FileLoading';
export { default as FileOkIcon } from './FileOk';
export { default as PropertiesIcon } from './Properties';
export { default as UploadFileIcon } from './UploadFile';
export { default as WarningIcon } from './Warning';
export { default as SearchAssetIcon } from './SearchAsset';
export { default as SearchIcon } from './SearchIcon';
export { default as StarIcon } from './StarIcon';
export { default as StarOutlineIcon } from './StarOutlineIcon';
export { default as BellIcon } from './BellIcon';
export { default as CompletedIcon } from './CompletedIcon';
export { default as CopyIcon } from './CopyIcon';
export { default as CompletedWhiteIcon } from './CompletedWhiteIcon';
export { default as ReplaceIcon } from './ReplaceIcon';
export { default as DisabledSmallReplaceIcon } from './DisabledSmallReplaceIcon';
export { default as SubmissionIcon } from './SubmissionIcon';
export { default as ReportInfoIcon } from './ReportInfo';
export { default as CancelIcon } from './CancelIcon';
export { default as CheckCircleIcon } from './CheckCircleIcon';
export { default as FileGroupIcon } from './FileGroup';
export { default as PersonIcon } from './PersonIcon';
export { default as ToggleOnIcon } from './ToggleOnIcon';
export { default as MappingFileIcon } from './MappingFile';
export { default as DownloadFileIcon } from './DownloadFileIcon';
export { default as ExpandIcon } from './ExpandIcon';
export { default as FileIcon } from './FileIcon';
export { default as GroupsIcon } from './GroupsIcon';
export { default as EditIcon } from './EditIcon';
export { default as DeleteIcon } from './DeleteIcon';
export { default as TogglesIcon } from './TogglesIcon';
export { default as DatasetsIcon } from './DatasetsIcon';
export { default as ArrowBackIcon } from './ArrowBackIcon';
export { default as EmulateUserIcon } from './EmulateUserIcon';
export { default as LockIcon } from './LockIcon';
export { default as LockOpenIcon } from './LockOpenIcon';
export { default as InviteIcon } from './InviteIcon';
export { default as BuildingIcon } from './BuildingIcon';
export { default as ReportFileIcon } from './ReportFileIcon';
export { default as ReportProblemIcon } from './ReportProblemIcon';
export { default as ReportWindowIcon } from './ReportWindowIcon';
export { default as MultiAppsIcon } from './MultiAppsIcon';
export { default as ReportOkIcon } from './ReportOkIcon';
export { default as SendIcon } from './SendIcon';
export { default as PackageReportCommentIcon } from './PackageReportCommentIcon';
export { default as UpdateIcon } from './UpdateIcon';
export { default as CachedIcon } from './CachedIcon';
export { default as ArrowDropDownIcon } from './ArrowDropDownIcon';
export { default as DocumentStackIcon } from './DocumentStackIcon';
export { default as CycleTimeIcon } from './CycleTimeIcon';
export { default as ResetIcon } from './ResetIcon';
export { default as RemoveIcon } from './RemoveIcon';
export { default as AddIcon } from './AddIcon';
export { default as AddCircleOutlineIcon } from './AddCircleOutlineIcon';
export { default as AddCircleIcon } from './AddCircleIcon';
export { default as GppGoodIcon } from './GppGoodIcon';
export { default as ArrowForwardIcon } from './ArrowForwardIcon';
export { default as ConnectorRunningIcon } from './ConnectorRunningIcon';
export { default as UpgradePlanIcon } from './UpgradePlanIcon';
export { default as CityIcon } from './CityIcon';
export { default as WarningAltIcon } from './WarningAltIcon';
export { default as InfoAltIcon } from './InfoAltIcon';
export { default as OpenInNew } from './OpenInNew';
export { default as ChevronLeftIcon } from './ChevronLeftIcon';
export { default as SwitchAccountIcon } from './SwitchAccountIcon';
export { default as ProviderGroupCircleIcon } from './ProviderGroupCircle';
export { default as CircleIcon } from './CircleIcon';
export { default as CategoryIcon } from './CategoryIcon';
export { default as HelpIcon } from './HelpIcon';
export { default as DashedArrowIcon } from './DashedArrowIcon';
export { default as SwapVertIcon } from './SwapVertIcon';
export { default as ErrorIcon } from './ErrorIcon';
export { default as RemoveCircleIcon } from './RemoveCircleIcon';
export { default as WatchLaterIcon } from './WatchLaterIcon';
export { default as Done } from '@mui/icons-material/Done';
export { default as SquareIcon } from '@mui/icons-material/Square';
export { default as DataGridIcon } from './DatagridIcon';
export { default as CherreLogoIcon } from './CherreLogoIcon';
export { default as VisibilityIcon } from '@mui/icons-material/Visibility';
export { default as VisibilityOffIcon } from '@mui/icons-material/VisibilityOff';
export { default as DataConnectionIcon } from './DataConnectionIcon';
export { default as ExpandMoreIcon } from './ExpandMore';
export { default as ExpandLessIcon } from './ExpandLess';
export { default as SettingsIcon } from '@mui/icons-material/Settings';
export { default as ReplayIcon } from '@mui/icons-material/Replay';
