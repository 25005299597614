import {
  CheckboxProps,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from '@mui/material';
import React, { forwardRef } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export interface ControlledCheckboxProps<
  T extends FieldValues,
  TName extends FieldPath<T>
> extends CheckboxProps {
  control: Control<T>;
  name: TName;
  helperText?: string;
  label?: string;
}

interface WithForwardReferenceType<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
> extends React.FC<ControlledCheckboxProps<T, TName>> {
  <T extends FieldValues, TName extends FieldPath<T>>(
    props: ControlledCheckboxProps<T, TName>
  ): ReturnType<React.FC<ControlledCheckboxProps<T, TName>>>;
}

export const ControlledCheckbox: WithForwardReferenceType = forwardRef(
  ({ control, name, helperText, ...inputProps }, _reference) => {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { ref: _reference, ...field }, fieldState }) => {
          const formHelper = fieldState.error?.message || helperText;
          const hasError = !!fieldState.error?.message;

          return (
            <FormControl required={inputProps.required} error={hasError}>
              <FormControlLabel
                sx={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    {...inputProps}
                    checked={field.value}
                    onChange={field.onChange}
                    name={field.name}
                  />
                }
                label={inputProps.label}
              />
              {formHelper && <FormHelperText>{formHelper}</FormHelperText>}
            </FormControl>
          );
        }}
      />
    );
  }
) as WithForwardReferenceType;

ControlledCheckbox.displayName = 'ControlledInput';
