import React, { useMemo } from 'react';
export { MaterialReactTable as Table } from 'material-react-table';
export type {
  MRT_TableOptions,
  MRT_Row,
  MRT_ColumnDef,
  MRT_SortingState,
  MRT_RowSelectionState,
  MRT_TableState,
  MRT_Icons as TableIcons,
} from 'material-react-table';

import { useIsSuspended } from '@cherre-frontend/data-fetching';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useMaterialReactTable, MRT_TableOptions } from 'material-react-table';

export type TableOptions<TData extends Record<string, unknown>> = Omit<
  MRT_TableOptions<TData>,
  'data'
> & {
  data: TData[] | undefined;
  skeletonRowCount?: number;
  skeletonBaseObj?: TData;
  skeletonIdField?: keyof TData;
};

const createArray = (length: number) => {
  if (length < 0) {
    throw new Error('cannot create array with negative length');
  }
  return Array.from(Array(length).keys());
};

function createFakeData<T extends Record<string, unknown>>(
  skeletonRowCount: number,
  skeletonBaseObj?: T,
  skeletonIdField?: string
) {
  if (skeletonBaseObj) {
    return createArray(skeletonRowCount).map(() => ({
      ...skeletonBaseObj,
      ...(skeletonIdField
        ? { [skeletonIdField]: (Math.random() * 100_000).toString() }
        : {}),
    }));
  }

  const fakeProxy = new Proxy({} as T, {
    get: (t) => t,
  });
  return createArray(skeletonRowCount).map(() => fakeProxy);
}

export const tableBaseStyle = {
  muiTableContainerProps: {
    sx: {
      height: '100%',
    },
  },
  muiTableProps: {
    sx: {
      '& .MuiTableRow-root td': {
        //TODO: move to use colors from theme
        backgroundColor: '#fff',
      },
    },
  },
  muiTablePaperProps: {
    elevation: 0,
  },
  muiTableHeadRowProps: {
    sx: {
      boxShadow: 'none',
      backgroundColor: '#fff',
      '&.Mui-selected': {
        backgroundColor: 'white',
      },
      '&> td': {
        backgroundColor: 'white',
      },
    },
  },
  muiTableHeadCellProps: ({ column, table }) => ({
    sx: {
      color: table.getState().sorting.some((s) => s.id === column.id)
        ? 'black'
        : 'var(--gray-700, #757575)',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: 'normal',
      fontFamily: 'Montserrat',
      padding: '10px',
      paddingBottom: '10px',
      paddingTop: '10px',
      verticalAlign: 'middle',
      borderBottom: table.getState().sorting.some((s) => s.id === column.id)
        ? '2px solid black'
        : '2px solid var(--gray-400, #BDBDBD)',
      '.Mui-TableHeadCell-Content-Wrapper': {
        paddingRight: '0',
        lineClamp: 2,
        WebkitLineClamp: 2,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
      },
      '.MuiTableSortLabel-icon': {
        color: 'black !important',
      },
      '.Mui-TableHeadCell-ResizeHandle-Wrapper': {
        marginRight: '-10px',
        padding: 'unset',
        width: '2px',
        height: 'calc(100% + 20px)',
        marginTop: '-10px',
        '& hr': {
          borderColor: 'transparent',
          borderWidth: '1px',
        },
      },
      '&:hover': {
        '.Mui-TableHeadCell-ResizeHandle-Wrapper': {
          backgroundColor: (theme) => theme.palette.grey[400],
        },
      },
      '.Mui-TableHeadCell-Content': {
        alignItems: 'flex-start',
        height: '100%',
      },
    },
  }),
  muiTableBodyCellProps: {
    sx: {
      color: 'var(--gray-900, #212121)',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 'normal',
      fontFamily: 'Montserrat',
      height: '42px',
      padding: '0 10px',
    },
  },
  muiTableBodyRowProps: {
    sx: {
      backgroundColor: '#fff',
      '&.MuiTableCell-root': {
        height: '28px',
      },
      '&:hover td': {
        backgroundColor: '#fff',
      },
      '&.Mui-selected': {
        backgroundColor: 'white',
      },
      '& > td': {
        backgroundColor: 'white',
      },
    },
  },
  muiBottomToolbarProps: {
    sx: {
      backgroundColor: '#fff',
    },
  },
  muiSelectCheckboxProps: {
    title: 'Select',
  },
  muiSelectAllCheckboxProps: {
    title: 'Select',
  },
  muiPaginationProps: {
    rowsPerPageOptions: [25, 50, 100],
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTable = <TData extends Record<string, any>>(
  options: TableOptions<TData>
) => {
  const fakedData = useMemo(
    () =>
      createFakeData<TData>(
        options.skeletonRowCount || 5,
        options.skeletonBaseObj,
        options.skeletonIdField as string
      ),
    [options.skeletonRowCount, options.skeletonBaseObj]
  );

  const suspended = useIsSuspended();
  return useMaterialReactTable({
    manualSorting: true,
    enablePagination: false,
    enableSelectAll: true,
    enableRowActions: false,
    enableRowSelection: true,
    enableColumnResizing: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enableColumnDragging: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
      },
      'mrt-row-select': {
        header: '',
        size: 0,
      },
    },
    defaultColumn: {
      muiTableHeadCellProps: {
        align: 'left',
      },
    },
    ...(tableBaseStyle as Partial<TableOptions<TData>>),
    icons: {
      SyncAltIcon: () => null,
      ArrowDownwardIcon: (props) => {
        return (
          <ArrowDownwardIcon {...props} sx={{ fontSize: '16px !important' }} />
        );
      },
    },
    ...options,
    data: options.data || fakedData,
    getRowId: options.data
      ? options.getRowId
      : () => (Math.random() * 100_000).toString(),
    state: {
      showSkeletons: Boolean(suspended && !options.data),
      showLoadingOverlay: Boolean(suspended && options.data),
      ...options.state,
    },
  });
};
