import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='30'
    height='36'
    viewBox='0 0 30 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21 9V1.5L28.5 9H21ZM23.121 0H12C10.3455 0 9 1.347 9 3V24C9 25.6545 10.3455 27 12 27H27C28.6545 27 30 25.6545 30 24V6.879L23.121 0Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.5 4.5H4.5V28.5C4.5 30.1545 5.8455 31.5 7.5 31.5H25.5V28.5H7.5V4.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 9H0V33C0 34.6545 1.3455 36 3 36H21V33H3V9Z'
      fill='currentColor'
    />
  </svg>,
  'FileGroup'
);
