import React, { useCallback, useMemo } from 'react';
import {
  Button,
  Grid,
  Dialog,
  Typography,
  IconButton,
  CloseIcon,
  Box,
} from '@cherre-frontend/ui';

type PopUpMessageProps = {
  title: string;
  description: string;
  onClose?: () => void;
  onSubmit?: () => void;
  open: boolean;
};

const PopUpMessage = ({
  title,
  description,
  onClose,
  onSubmit,
  open,
}: PopUpMessageProps) => {
  const onCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const onContinue = useCallback(() => {
    if (onSubmit) {
      onSubmit();
    }
  }, [onSubmit]);

  const modalOpen = useMemo(() => open, [open]);

  return (
    <Dialog
      open={modalOpen}
      maxWidth='sm'
      onClose={onCancel}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '10px',
        },
      }}
    >
      <Grid padding='20px' data-testid='warning-show-container'>
        <Grid marginBottom='20px'>
          <Box
            display={'flex'}
            alignItems='center'
            justifyContent={'space-between'}
          >
            <Typography variant='h5' fontWeight='600' marginBottom='6px'>
              {title}
            </Typography>
            <Typography variant='h5' fontWeight='600' marginBottom='6px'>
              <IconButton aria-label='close' onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </Typography>
          </Box>

          <Typography variant='body1' marginBottom='6px'>
            {description}
          </Typography>
        </Grid>
        <Grid display='flex' justifyContent='flex-end' gap='10px'>
          <Button
            size='small'
            color='primary'
            onClick={onCancel}
            data-testid='warning-show-cancel'
          >
            Cancel
          </Button>
          <Button
            size='small'
            onClick={onContinue}
            variant='contained'
            color='primary'
            data-testid='warning-show-continue'
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PopUpMessage;
