import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5 1.66797H11.6666C11.2066 1.66797 10.8333 2.0413 10.8333 2.5013V3.33464H16.6666V5.0013H10.8333V6.66797H15.8333V8.33464H10.8333V10.0013H15V11.668H10.8333V17.5013C10.8333 17.9621 11.2066 18.3346 11.6666 18.3346H13.3333V15.8346H15.8333V18.3346H17.5C17.96 18.3346 18.3333 17.9621 18.3333 17.5013V2.5013C18.3333 2.0413 17.96 1.66797 17.5 1.66797ZM8.33329 6.66797H2.49996C2.03996 6.66797 1.66663 7.0413 1.66663 7.5013V8.33464H6.66663V10.0013H1.66663V11.668H4.99996V13.3346H1.66663V17.5013C1.66663 17.9621 2.03996 18.3346 2.49996 18.3346H4.16663V15.8346H6.66663V18.3346H8.33329C8.79329 18.3346 9.16663 17.9621 9.16663 17.5013V7.5013C9.16663 7.0413 8.79329 6.66797 8.33329 6.66797Z'
      fill='currentColor'
    />
  </svg>,
  'BuildingOffice'
);
