import React from 'react';
import { Link, useHistory } from 'react-router-dom';

//ALWAYS IMPORT ONLY THE COMPONENT YOU NEED FROM MATERIAL
import ArrowBack from '@mui/icons-material/ArrowBack';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styled from '@mui/styled-engine';

/* THIS NEW VERSION OF ./src/components/Header.tsx */

const getTemplateAreas = (subtitle: boolean, backLink: boolean) => {
  if (subtitle && backLink) {
    return `"back title actions" ". subtitle actions"`;
  }

  if (subtitle && !backLink) {
    return `"title actions" "subtitle actions"`;
  }

  if (!subtitle && backLink) {
    return `"back title actions"`;
  }

  return `"title actions"`;
};

type ContainerProps = {
  backLink?: string | boolean;
  subtitle?: React.ReactNode;
} & BoxProps;

const BoxStyled = styled(Box, {
  shouldForwardProp: (propName) => !['backLink', 'subtitle'].includes(propName),
})<ContainerProps>`
  padding: 20px 0;
  display: grid;
  gap: 0px 12px;
  grid-template-columns: ${({ backLink }) =>
    backLink ? `auto 1fr auto` : `1fr auto`};
  grid-template-areas: ${({ subtitle, backLink }) =>
    getTemplateAreas(!!subtitle, !!backLink)};
  align-items: center;

  .actions {
    gap: 14px;
  }
`;

export type PageHeaderProps = {
  title: string;
  subtitle?: React.ReactNode;
  backLink?: string | boolean;
  children?: React.ReactNode;
  'data-testid'?: string;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  backLink,
  children,
  ['data-testid']: testid,
}) => {
  const { goBack } = useHistory();
  const isBackLinkString = typeof backLink === 'string';

  return (
    <BoxStyled data-testid={testid} subtitle={subtitle} backLink={backLink}>
      {backLink && (
        <Box gridArea='back'>
          <IconButton
            style={{ color: '#000' }}
            size='small'
            {...(isBackLinkString
              ? { component: Link, to: backLink }
              : { onClick: goBack })}
          >
            <ArrowBack />
          </IconButton>
        </Box>
      )}
      <Box gridArea='title'>
        <Typography variant='h3'>{title}</Typography>
      </Box>
      {subtitle && (
        <Box gridArea='subtitle'>
          {Array.isArray(subtitle) ? (
            subtitle.map((text, idx) => (
              <Typography key={idx} variant='body1'>
                {text}
              </Typography>
            ))
          ) : (
            <Typography variant='body1'>{subtitle}</Typography>
          )}
        </Box>
      )}
      <Box gridArea='actions' className='actions' gap='10px' display='flex'>
        {children}
      </Box>
    </BoxStyled>
  );
};

PageHeader.displayName = '@cherre-frontend/PageHeader';
