import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='21'
    height='23'
    viewBox='0 0 21 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Objects/document'>
      <path
        id='Subtract'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 0.5H11.25L18 7.1V10.5C16.3314 10.5 14.7821 11.0108 13.5 11.8847V11.5H4.5V13.7H11.5994C11.1027 14.3613 10.706 15.1022 10.432 15.9H4.5V18.1H10.0098C10.0033 18.2325 10 18.3659 10 18.5C10 19.9571 10.3896 21.3233 11.0703 22.5H2.23875C1.00125 22.5 0 21.51 0 20.3L0.0112503 2.7C0.0112503 1.49 1.0125 0.5 2.25 0.5ZM10.125 2.15V8.2H16.3125L10.125 2.15Z'
        fill='#DF2467'
      />
      <path
        id='Fill-937'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.7984 15.5L16.1502 19.1177L14.2016 17.1854L13 18.377L16.1502 21.5L21 16.6916L19.7984 15.5Z'
        fill='#DF2467'
      />
    </g>
  </svg>,
  'ReportOkIcon'
);
