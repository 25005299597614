import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.3334 5V0.833333L16.5 5H12.3334ZM13.5117 0H7.33335C6.41419 0 5.66669 0.748333 5.66669 1.66667V13.3333C5.66669 14.2525 6.41419 15 7.33335 15H15.6667C16.5859 15 17.3334 14.2525 17.3334 13.3333V3.82167L13.5117 0Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.83335 2.5H3.16669V15.8333C3.16669 16.7525 3.91419 17.5 4.83335 17.5H14.8334V15.8333H4.83335V2.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.33335 5H0.666687V18.3333C0.666687 19.2525 1.41419 20 2.33335 20H12.3334V18.3333H2.33335V5Z'
      fill='currentColor'
    />
  </svg>,

  'DocumentStackIcon'
);
