import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 17.7055C9.31 17.7055 8.75 17.1455 8.75 16.4555C8.75 15.7655 9.31 15.2055 10 15.2055C10.69 15.2055 11.25 15.7655 11.25 16.4555C11.25 17.1455 10.69 17.7055 10 17.7055ZM9 8.4555H11V14.4555H9V8.4555ZM19.895 19.0085L10.895 1.0085C10.556 0.3305 9.444 0.3305 9.105 1.0085L0.105004 19.0085C-0.0489959 19.3185 -0.0329959 19.6865 0.149004 19.9815C0.332004 20.2755 0.653004 20.4555 1 20.4555H19C19.347 20.4555 19.668 20.2755 19.851 19.9815C20.033 19.6865 20.049 19.3185 19.895 19.0085Z'
      fill='inherit'
    />
  </svg>,
  'WarningAltIcon'
);
