import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionSummary,
  AccordionDetails,
  Accordion as MuiAccordion,
  styled,
  AccordionProps as MUIAccordionProps,
} from '@mui/material';
import { useIsSuspended } from '@cherre-frontend/data-fetching';

const AccordionStyled = styled(MuiAccordion)`
  border-radius: 10px;
  position: relative;
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:before {
    display: none;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: left 0.2s;
    z-index: -1;
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.secondary.light};
    content: '';
  }

  &.Mui-expanded {
    margin-top: 0;
    padding: 10px 0;
  }

  &.Mui-disabled {
    background: white;
  }

  &.Mui-disabled:after {
    background: none;
  }

  &.Mui-expanded:after {
    left: -2px;
  }
`;

const AccordionSummaryStyled = styled(AccordionSummary)`
  &.Mui-expanded {
    min-height: unset;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 7px 0;
  }

  .MuiAccordionSummary-content {
    align-items: center;
    gap: 10px;
    margin: 7px 0;

    > svg {
      color: ${({ theme }) => theme.palette.grey[400]};
    }
  }
`;

const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
`;

export interface AccordionProps {
  icon?: React.ReactElement;
  summary?: React.ReactNode;
  defaultExpanded?: boolean;
  expanded?: boolean;
  onChange?: MUIAccordionProps['onChange'];
}

const Accordion: React.FC<AccordionProps> = ({
  icon,
  summary,
  children,
  defaultExpanded,
  expanded,
  onChange,
}) => {
  const suspended = useIsSuspended();

  return (
    <AccordionStyled
      elevation={0}
      disabled={suspended}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummaryStyled
        expandIcon={suspended ? <></> : <ExpandMoreIcon />}
      >
        {icon}
        {summary}
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
    </AccordionStyled>
  );
};

export default Accordion;
