import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='19'
    height='21'
    viewBox='0 0 19 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.4036 0L16.6458 6.24219L16.6471 12.5887C16.3105 12.5203 15.9622 12.4844 15.6055 12.4844C12.7326 12.4844 10.4036 14.8133 10.4036 17.6862C10.4036 18.8578 10.791 19.9389 11.4446 20.8085L2.07032 20.8073C0.977942 20.8073 0.0846263 19.9541 0.00567272 18.8813L0 18.7266L0.0104036 2.08073C0.0104036 0.988346 0.854066 0.0855506 1.92609 0.00573523L2.08073 0H10.4036ZM9.36328 1.56055V7.28255H15.0853L9.36328 1.56055Z'
      fill='currentColor'
    />
    <path
      d='M17.964 15.0269L19 16.0832L14.7955 20.2256L12.3294 17.7544L13.3651 16.6978L14.7955 18.1282L17.964 15.0269Z'
      fill='currentColor'
    />
  </svg>,
  'MappingFile'
);
