import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 0C10.491 0 8.26 1.31 7.021 3.357C7.987 3.814 9.018 4.62 9.992 6.003L9.175 6.58C7.678 4.454 6.393 4 5 4C2.243 4 0 6.243 0 9C0 11.446 1.768 13.479 4.092 13.908L10 8L15.522 13.522C18.138 12.507 20 9.97 20 7C20 3.14 16.86 0 13 0Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.707 14.291L9.99997 9.58398L5.29297 14.291L6.70697 15.705L8.99997 13.412V20H11V13.412L13.293 15.705L14.707 14.291Z'
      fill='currentColor'
    />
  </svg>,
  'CloudUpload'
);
