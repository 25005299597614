import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 6.00003H13.586L11.293 8.29303L12.707 9.70703L16.707 5.70703C17.098 5.31603 17.098 4.68403 16.707 4.29303L12.707 0.29303L11.293 1.70703L13.586 4.00003H6C2.691 4.00003 0 6.69103 0 10H2C2 7.79403 3.794 6.00003 6 6.00003ZM18 10C18 12.206 16.206 14 14 14H6.414L8.707 11.707L7.293 10.293L3.293 14.293C2.902 14.684 2.902 15.316 3.293 15.707L7.293 19.707L8.707 18.293L6.414 16H14C17.309 16 20 13.309 20 10H18Z'
      fill='#E88819'
    />
  </svg>,
  'ReplaceIcon'
);
