import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.41537 10.0687L3.42162 12.0632C4.2787 14.8843 6.9019 16.9437 9.99871 16.9437C13.2643 16.9437 15.9998 14.6536 16.6973 11.5965H18.2556C17.5345 15.5015 14.11 18.4715 9.99871 18.4715C6.49398 18.4715 3.48655 16.3128 2.22842 13.2564L0.832031 14.6521V10.0687H5.41537ZM9.99901 11.0236C11.7567 11.0236 12.8636 12.1305 12.8636 13.8882H7.13443C7.13443 12.1305 8.2413 11.0236 9.99901 11.0236ZM9.99894 1.66602C13.5037 1.66602 16.5111 3.82477 17.77 6.88109L19.1656 5.48546V10.0688H14.5823L16.5768 8.07429C15.7197 5.25324 13.0965 3.1938 9.99894 3.1938C6.73407 3.1938 3.99858 5.48394 3.30115 8.54102H1.74205C2.46317 4.63602 5.88768 1.66602 9.99894 1.66602ZM9.99901 6.24928C11.0539 6.24928 11.9087 7.10407 11.9087 8.159C11.9087 9.21393 11.0539 10.0687 9.99901 10.0687C8.94408 10.0687 8.08929 9.21393 8.08929 8.159C8.08929 7.10407 8.94408 6.24928 9.99901 6.24928Z'
      fill='#9E9E9E'
    />
  </svg>,
  'EmulateUserIcon'
);
