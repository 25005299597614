import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.6038 10.4991V11.3025C16.0682 11.3959 16.4991 11.5818 16.8805 11.8348L17.4528 11.2632L18.4837 12.2941L17.9121 12.8657C18.1658 13.2485 18.3518 13.6794 18.4465 14.1445H19.25V15.6027H18.4465C18.3518 16.0672 18.1666 16.498 17.9136 16.8801L18.4845 17.4502L17.4543 18.4819L16.8826 17.911C16.5006 18.1647 16.069 18.3507 15.6045 18.4447V19.2489H14.1464V18.4454C13.6819 18.3514 13.2503 18.1655 12.8675 17.9118L12.2974 18.4834L11.2657 17.4532L11.8366 16.8815C11.5829 16.4988 11.3977 16.0679 11.3029 15.6027H10.4995V14.1445H11.3029C11.3977 13.6801 11.5829 13.2485 11.8366 12.8664L11.2657 12.2956L12.2967 11.2646L12.8675 11.8355C13.2503 11.5818 13.6812 11.3959 14.1456 11.3025V10.4991H15.6038ZM1.75 12.663C3.06893 13.5299 5.26786 14.1445 8.31182 14.1445C8.58085 14.1445 8.83822 14.1365 9.09413 14.1278C9.06278 14.3727 9.04091 14.6199 9.04091 14.8736C9.04091 15.368 9.10871 15.8448 9.22464 16.3027C8.92134 16.3201 8.61731 16.3318 8.31182 16.3318C5.05132 16.3318 1.75 15.33 1.75 13.4155V12.663ZM14.874 13.4155C14.0676 13.4155 13.4158 14.0687 13.4158 14.8736C13.4158 15.6785 14.0676 16.3318 14.874 16.3318C15.6789 16.3318 16.3322 15.6785 16.3322 14.8736C16.3322 14.0687 15.6789 13.4155 14.874 13.4155ZM1.75036 9.01758C3.06929 9.88447 5.26823 10.4991 8.31218 10.4991C9.42477 10.4991 10.4193 10.4138 11.3051 10.2672C10.5075 10.8855 9.87316 11.7028 9.48237 12.6499C9.11272 12.6711 8.72849 12.6864 8.31218 12.6864C3.44454 12.6864 1.80249 11.111 1.75159 10.5266L1.75036 10.4991V9.01758ZM14.874 5.37212V6.85363C14.874 7.41431 13.2583 9.04091 8.31218 9.04091C3.44454 9.04091 1.80249 7.46553 1.75159 6.88113L1.75036 6.85363V5.37212C3.06929 6.23901 5.26823 6.85363 8.31218 6.85363C11.3561 6.85363 13.5551 6.23901 14.874 5.37212ZM8.31218 1.75C11.0003 1.75 13.7104 2.43243 14.5816 3.74406C13.9298 4.44253 12.0998 5.39545 8.31218 5.39545C4.52455 5.39545 2.69454 4.44253 2.04273 3.74406C2.91399 2.43243 5.62402 1.75 8.31218 1.75Z'
      fill='#9E9E9E'
    />
  </svg>,
  'Data Settings'
);
