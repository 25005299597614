import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6458 6.24219L10.4036 0H2.08073L1.92609 0.00573523C0.854066 0.0855506 0.0104036 0.988346 0.0104036 2.08073L0 18.7266L0.00567272 18.8813C0.0846263 19.9541 0.977942 20.8073 2.07032 20.8073L11.4446 20.8085C10.791 19.9389 10.4036 18.8578 10.4036 17.6862C10.4036 14.8133 12.7326 12.4844 15.6055 12.4844C15.9622 12.4844 16.3105 12.5203 16.6471 12.5887L16.6458 6.24219ZM9.36328 7.28255V1.56055L15.0853 7.28255H9.36328ZM14.654 20.2991C15.0507 20.5331 15.5073 20.6764 16 20.6764C17.4707 20.6764 18.6667 19.4798 18.6667 18.0098C18.6667 17.5171 18.5233 17.0604 18.2893 16.6638L14.654 20.2991ZM16 15.3431C14.5293 15.3431 13.3333 16.5391 13.3333 18.0098C13.3333 18.5024 13.4773 18.9598 13.7107 19.3564L17.3467 15.7204C16.95 15.4871 16.4927 15.3431 16 15.3431ZM12 18.0098C12 15.8038 13.794 14.0098 16 14.0098C18.206 14.0098 20 15.8038 20 18.0098C20 20.2158 18.206 22.0098 16 22.0098C13.794 22.0098 12 20.2158 12 18.0098Z'
      fill='currentColor'
    />
  </svg>,
  'FileFailed'
);
