import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

type DatePickerInputProps = DatePickerProps<unknown> &
  React.RefAttributes<HTMLDivElement> & {
    helperText?: string;
    placeholder?: string;
    testID?: string;
  };

const StyledDatePicker = styled(DatePicker)<DatePickerInputProps>(
  ({ theme }) => ({
    margin: '0px',
    '& .MuiFormHelperText-root': {
      fontFamily: theme.typography.body2.fontFamily,
      margin: '5px 0',
      fontSize: theme.typography.body2.fontSize,
    },
  })
);

export const DatePickerInput = (props: DatePickerInputProps) => {
  const {
    format = 'MMMM DD, YYYY',
    helperText,
    placeholder,
    slotProps,
    onOpen,
    value,
    ...newProps
  } = props;
  moment.defineLocale('date-picker-en', {
    parentLocale: 'en',
    longDateFormat: {
      LTS: 'h:mm:ss A',
      LT: 'h:mm A',
      L: 'MM/DD/YYYY',
      LL: 'MMMM D, YYYY',
      ll: 'MMM D, YYYY',
      LLL: 'MMMM D, YYYY h:mm A',
      lll: 'MMM D, YYYY h:mm A',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      llll: 'ddd, MMM D, YYYY h:mm A',
      l: 'M/D/YYYY',
      lts: 'h:mm:ss a',
      lt: 'h:mm a',
    },
  });

  const [open, setOpen] = useState(false);

  const formattedValue = value ? moment(value) : null;

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={'date-picker-en'}
    >
      <StyledDatePicker
        value={formattedValue}
        format={format}
        open={open}
        onOpen={() => {
          setOpen(true);
          if (onOpen) {
            onOpen();
          }
        }}
        onClose={() => setOpen(false)}
        slotProps={{
          day: {
            sx: {
              '&.Mui-selected': {
                backgroundColor: (theme) =>
                  `${theme.palette.primary.main} !important`,
              },
              '&:hover': {
                backgroundColor: (theme) => theme.palette.grey[200],
              },
            },
          },
          textField: {
            size: 'small',
            helperText,
            sx: {
              padding: '0px',
            },
            inputProps: {
              'data-testid': props.testID,
              placeholder,
              readOnly: true,
              onClick: () => {
                setOpen(true);
                if (onOpen) {
                  onOpen();
                }
              },
            },
          },
          ...(slotProps || {}),
        }}
        {...newProps}
      />
    </LocalizationProvider>
  );
};
