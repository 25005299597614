import { useState, useRef } from 'react';

export const useRect = (save: boolean) => {
  const contentRef = useRef<HTMLElement>(null);
  const [savedRect, setRect] = useState<DOMRect | null>(null);
  if (save && !savedRect) {
    const rect = contentRef.current?.getBoundingClientRect();
    if (rect) {
      setRect(rect);
    }
  }
  if (!save && savedRect) {
    setRect(null);
  }
  return [savedRect, contentRef] as const;
};
