import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.29166 5.29427C6.49999 4.08594 8.15833 3.33594 9.99999 3.33594C13.6833 3.33594 16.6583 6.31927 16.6583 10.0026C16.6583 13.6859 13.6833 16.6693 9.99999 16.6693C6.89166 16.6693 4.29999 14.5443 3.55833 11.6693H5.29166C5.97499 13.6109 7.82499 15.0026 9.99999 15.0026C12.7583 15.0026 15 12.7609 15 10.0026C15 7.24427 12.7583 5.0026 9.99999 5.0026C8.61666 5.0026 7.38333 5.5776 6.48333 6.48594L9.16666 9.16927H3.33333V3.33594L5.29166 5.29427Z'
      fill='#E53935'
    />
  </svg>,
  'ResetIcon'
);
