import {
  CherreValue,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import React from 'react';
import { Panel } from './Panel';

export type SuspenseListProps<T> = {
  id: string;
  selector: CherreValue<T[]>;
  children: React.ReactNode;
  skeletonCount?: number;
  gap?: number | string;
};

const SuspenseList_INNER = (<T,>({
  id,
  children,
  skeletonCount,
  selector,
  gap,
}: SuspenseListProps<T>) => {
  const isSuspended = useIsSuspended();
  const data = useCherreValue(selector);
  if (isSuspended || !data) {
    return (
      <div style={{ display: 'grid', gap: gap || '20px' }}>
        {Array(skeletonCount || 3)
          .fill(null)
          .map((_, idx) => (
            <Panel id={`${id}-${idx}`} key={`${id}-${idx}`}>
              {children}
            </Panel>
          ))}
      </div>
    );
  }
  return (
    <div style={{ display: 'grid', gap: gap || '20px' }}>
      {data.map((d, idx) => (
        <Panel id={`${id}-${idx}`} key={`${id}-${idx}`}>
          {React.cloneElement(children as any, { data: d })}
        </Panel>
      ))}
    </div>
  );
}) satisfies React.FC<SuspenseListProps<any>>;

const SuspenseList = <T,>(props: SuspenseListProps<T>) => {
  return (
    <Panel id={props.id}>
      <SuspenseList_INNER {...props} />
    </Panel>
  );
};

(SuspenseList as React.FC).displayName = '@cherre-frontend/ui/SuspenseList';

export default SuspenseList;
