import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='13'
    height='14'
    viewBox='0 0 13 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Actions/Download-File'>
      <path
        id='Combined-Shape'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.1161 9.06563L11.1158 11.6132L12.0671 10.6813L12.75 11.3776L10.6285 13.4582L8.5058 11.3777L9.18851 10.6811L10.1405 11.6139L10.1407 9.06563H11.1161ZM7.37728 0.125L11.2786 4.02637L11.2794 7.99292C11.0691 7.95017 10.8514 7.92773 10.6284 7.92773C8.83286 7.92773 7.37728 9.38332 7.37728 11.1789C7.37728 11.9111 7.61936 12.5868 8.02785 13.1303L2.16895 13.1296C1.48621 13.1296 0.927891 12.5963 0.878545 11.9258L0.875 11.8291L0.881502 1.42546C0.881502 0.742716 1.40879 0.178469 2.07881 0.128585L2.17546 0.125H7.37728ZM6.72705 1.10034V4.67659H10.3033L6.72705 1.10034Z'
        fill='currentColor'
      />
    </g>
  </svg>,
  'DownloadFileIcon'
);
