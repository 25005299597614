import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='65'
    height='38'
    viewBox='0 0 65 38'
    fill='none'
  >
    <path
      d='M3.01698 34.7825C3.02904 35.0313 2.94762 35.2755 2.78873 35.4673C2.61478 35.6511 2.38245 35.7689 2.13137 35.8005L3.09686 37.1997H2.55363L1.67944 35.8302H1.09056V37.1997H0.666016V33.8148H1.8415C2.15848 33.792 2.47236 33.8898 2.72025 34.0887C2.81721 34.1742 2.89453 34.2797 2.94693 34.3978C2.99933 34.516 3.02556 34.6441 3.02382 34.7734M1.80726 35.5175C2.01599 35.5319 2.22218 35.4648 2.38245 35.3303C2.50654 35.1993 2.5757 35.0258 2.5757 34.8453C2.5757 34.6649 2.50654 34.4913 2.38245 34.3603C2.22909 34.2317 2.03209 34.1671 1.83237 34.18H1.09056V35.5175H1.80726Z'
      fill='#636362'
    />
    <path
      d='M4.27344 33.8145H6.34822V34.1797H4.6957V35.3118H6.25235V35.6724H4.6957V36.8205H6.34822V37.1903H4.27344V33.8145Z'
      fill='#636362'
    />
    <path
      d='M8.37011 33.8145H8.88367L10.0249 37.1903H9.57983L9.28767 36.3434H7.94785L7.65798 37.2017H7.21289L8.37011 33.8145ZM8.05969 35.9988H9.18268L8.62347 34.3349L8.05969 35.9988Z'
      fill='#636362'
    />
    <path
      d='M11.446 36.8205H12.9479V37.1903H11.0215V33.8145H11.446V36.8205Z'
      fill='#636362'
    />
    <path
      d='M15.877 33.8145H17.954V34.1797H16.3015V35.3118H17.8559V35.6724H16.3015V36.8205H17.954V37.1903H15.877V33.8145Z'
      fill='#636362'
    />
    <path
      d='M21.0166 34.6363C20.955 34.3076 20.7153 34.1433 20.2931 34.1433C20.108 34.1308 19.9242 34.1824 19.7727 34.2894C19.7164 34.3302 19.6706 34.3838 19.6389 34.4456C19.6071 34.5075 19.5904 34.5759 19.5901 34.6455C19.5858 34.7025 19.5935 34.7598 19.6128 34.8137C19.632 34.8675 19.6624 34.9168 19.7019 34.9582C19.8085 35.0465 19.9332 35.1104 20.0671 35.1453L20.7701 35.3873C20.984 35.4539 21.1794 35.5695 21.3407 35.7251C21.418 35.8145 21.4764 35.9187 21.5124 36.0313C21.5484 36.1439 21.5613 36.2626 21.5502 36.3804C21.5391 36.4981 21.5043 36.6123 21.4479 36.7162C21.3915 36.8201 21.3147 36.9116 21.222 36.985C20.9642 37.1746 20.6493 37.2705 20.3296 37.2566C20.0018 37.2701 19.6793 37.1701 19.4166 36.9736C19.2987 36.8791 19.2042 36.7587 19.1404 36.6217C19.0767 36.4847 19.0455 36.3349 19.0491 36.1838H19.4622C19.4565 36.2808 19.4742 36.3777 19.5139 36.4663C19.5537 36.555 19.6142 36.6327 19.6905 36.6928C19.8654 36.8167 20.0767 36.8786 20.2908 36.8686C20.4974 36.8802 20.702 36.8233 20.8728 36.7065C20.9313 36.6671 20.9804 36.6154 21.017 36.5552C21.0536 36.4949 21.0767 36.4274 21.0847 36.3574C21.0927 36.2873 21.0855 36.2163 21.0635 36.1494C21.0415 36.0824 21.0053 36.0209 20.9573 35.9693C20.8447 35.8697 20.7114 35.7964 20.567 35.7547L19.9712 35.5265C19.742 35.4627 19.5283 35.3524 19.3436 35.2024C19.2688 35.1293 19.2109 35.0408 19.1738 34.943C19.1367 34.8453 19.1215 34.7406 19.129 34.6363C19.1275 34.5168 19.1537 34.3985 19.2056 34.2908C19.2575 34.1831 19.3336 34.0888 19.428 34.0155C19.6707 33.836 19.969 33.7479 20.2702 33.7667C20.5654 33.7432 20.8594 33.8239 21.1011 33.9949C21.1972 34.0739 21.2764 34.1713 21.334 34.2815C21.3917 34.3917 21.4266 34.5124 21.4366 34.6363H21.0166Z'
      fill='#636362'
    />
    <path
      d='M22.1719 33.8145H24.7945V34.1797H23.6943V37.1903H23.2697V34.1797H22.1719V33.8145Z'
      fill='#636362'
    />
    <path
      d='M26.3392 33.8145H26.855L27.9963 37.1903H27.5558L27.2567 36.3434H25.9146L25.6248 37.2017H25.1797L26.3392 33.8145ZM26.0288 35.9988H27.1426L26.5834 34.3349L26.0288 35.9988Z'
      fill='#636362'
    />
    <path
      d='M28.2656 33.8145H30.8882V34.1797H29.7903V37.1903H29.3658V34.1797H28.2656V33.8145Z'
      fill='#636362'
    />
    <path
      d='M31.7812 33.8145H33.856V34.1797H32.2035V35.3118H33.7602V35.6724H32.2035V36.8205H33.856V37.1903H31.7812V33.8145Z'
      fill='#636362'
    />
    <path
      d='M38.9464 34.6367C38.8848 34.308 38.6451 34.1437 38.2228 34.1437C38.0378 34.1312 37.854 34.1827 37.7024 34.2897C37.6462 34.3306 37.6003 34.3841 37.5686 34.446C37.5369 34.5078 37.5202 34.5763 37.5198 34.6458C37.5155 34.7029 37.5233 34.7602 37.5426 34.814C37.5618 34.8679 37.5922 34.9171 37.6317 34.9585C37.7383 35.0468 37.8629 35.1107 37.9969 35.1457L38.6999 35.3876C38.9138 35.4542 39.1092 35.5699 39.2705 35.7254C39.3478 35.8149 39.4062 35.919 39.4422 36.0317C39.4782 36.1443 39.491 36.263 39.48 36.3807C39.4689 36.4984 39.4341 36.6127 39.3777 36.7166C39.3213 36.8205 39.2445 36.9119 39.1518 36.9854C38.8931 37.1726 38.5783 37.2659 38.2594 37.2501C37.9315 37.2636 37.6091 37.1636 37.3464 36.9671C37.2281 36.873 37.1333 36.7526 37.0695 36.6156C37.0057 36.4785 36.9747 36.3285 36.9789 36.1774H37.3943C37.3885 36.2743 37.4062 36.3712 37.446 36.4599C37.4857 36.5485 37.5463 36.6262 37.6226 36.6863C37.7974 36.8102 38.0087 36.8721 38.2228 36.8621C38.4294 36.8737 38.634 36.8168 38.8049 36.7C38.8633 36.6606 38.9125 36.6089 38.9491 36.5487C38.9857 36.4884 39.0087 36.4209 39.0168 36.3509C39.0248 36.2808 39.0176 36.2099 38.9956 36.1429C38.9736 36.0759 38.9373 36.0145 38.8893 35.9628C38.7768 35.8632 38.6435 35.7899 38.499 35.7482L37.9033 35.52C37.6717 35.4541 37.4563 35.3406 37.271 35.1868C37.1963 35.1137 37.1383 35.0252 37.1013 34.9274C37.0642 34.8296 37.0489 34.725 37.0565 34.6207C37.055 34.5011 37.0812 34.3829 37.1331 34.2752C37.1849 34.1674 37.2611 34.0732 37.3555 33.9999C37.5982 33.8204 37.8965 33.7323 38.1977 33.7511C38.4929 33.7275 38.7869 33.8083 39.0286 33.9793C39.1234 34.0589 39.2014 34.1566 39.2578 34.2667C39.3143 34.3769 39.3481 34.4973 39.3572 34.6207L38.9464 34.6367Z'
      fill='#636362'
    />
    <path
      d='M41.8027 33.7583C42.0018 33.7528 42.1997 33.7908 42.3826 33.8697C42.5655 33.9485 42.7291 34.0663 42.8618 34.2148C43.1241 34.598 43.2645 35.0515 43.2645 35.5159C43.2645 35.9802 43.1241 36.4337 42.8618 36.8169C42.7233 36.9578 42.5581 37.0697 42.3758 37.1461C42.1936 37.2225 41.998 37.2619 41.8004 37.2619C41.6028 37.2619 41.4072 37.2225 41.225 37.1461C41.0427 37.0697 40.8776 36.9578 40.7391 36.8169C40.4789 36.4337 40.3398 35.9813 40.3398 35.5181C40.3398 35.055 40.4789 34.6026 40.7391 34.2194C40.8713 34.0697 41.0351 33.9511 41.2186 33.8722C41.4021 33.7933 41.6008 33.756 41.8004 33.7629M41.8004 34.1418C41.6565 34.1363 41.5134 34.1659 41.3834 34.2279C41.2534 34.2899 41.1404 34.3826 41.054 34.4979C40.8528 34.7976 40.7565 35.1556 40.7801 35.5159C40.7557 35.8733 40.8522 36.2286 41.054 36.5247C41.1468 36.632 41.2616 36.718 41.3906 36.777C41.5196 36.836 41.6597 36.8665 41.8016 36.8665C41.9434 36.8665 42.0835 36.836 42.2125 36.777C42.3415 36.718 42.4563 36.632 42.5491 36.5247C42.7517 36.2289 42.849 35.8736 42.8252 35.5159C42.8482 35.1553 42.7511 34.7973 42.5491 34.4979C42.462 34.3828 42.3485 34.2904 42.2182 34.2284C42.0879 34.1664 41.9446 34.1367 41.8004 34.1418Z'
      fill='#636362'
    />
    <path
      d='M44.291 33.8145H46.3407V34.1797H44.7156V35.3026H46.2288V35.6656H44.7156V37.1903H44.291V33.8145Z'
      fill='#636362'
    />
    <path
      d='M47.0234 33.8145H49.6483V34.1797H48.5481V37.1903H48.1236V34.1797H47.0234V33.8145Z'
      fill='#636362'
    />
    <path
      d='M50.6557 33.8145L51.4774 36.6219L52.3083 34.1637H52.5936L53.4198 36.6219L54.3648 33.8145H54.8304L53.6389 37.1903H53.1939L52.4589 35.0287L51.6966 37.1903H51.2515L50.1855 33.8145H50.6557Z'
      fill='#636362'
    />
    <path
      d='M56.3574 33.8145H56.871L58.0122 37.1903H57.5717L57.2704 36.3434H55.9283L55.6384 37.2017H55.1934L56.3574 33.8145ZM56.0447 35.9988H57.1677L56.6085 34.3349L56.0447 35.9988Z'
      fill='#636362'
    />
    <path
      d='M61.2178 34.7831C61.2299 35.0318 61.1485 35.2761 60.9896 35.4678C60.8156 35.6516 60.5833 35.7694 60.3322 35.8011L61.2977 37.2002H60.7499L59.8757 35.8307H59.2868V37.2002H58.8691V33.8153H60.0446C60.3593 33.7943 60.6704 33.892 60.9165 34.0892C61.0135 34.1747 61.0908 34.2802 61.1432 34.3984C61.1956 34.5166 61.2218 34.6447 61.2201 34.774M60.0104 35.518C60.2191 35.5325 60.4253 35.4654 60.5856 35.3309C60.7097 35.1999 60.7788 35.0263 60.7788 34.8459C60.7788 34.6654 60.7097 34.4918 60.5856 34.3608C60.4322 34.2322 60.2352 34.1677 60.0355 34.1805H59.2846V35.518H60.0104Z'
      fill='#636362'
    />
    <path
      d='M62.2598 33.8145H64.3345V34.1797H62.682V35.3118H64.2387V35.6724H62.682V36.8205H64.3345V37.1903H62.2598V33.8145Z'
      fill='#636362'
    />
    <path
      d='M52.7521 5.79987C49.0476 5.79987 45.9891 7.36109 44.017 9.89465L44.0056 9.87411C43.3386 9.02697 42.5448 8.28788 41.6523 7.68292V29.0721H46.425V16.9886C46.425 13.1449 48.9106 10.2758 52.7521 10.2758C55.0026 10.2758 56.7829 11.2664 57.874 12.855V7.07806C56.3064 6.21492 54.5413 5.77443 52.7521 5.79987Z'
      fill='#BED366'
    />
    <path
      d='M40.3219 17.1714V29.0814H35.5287V16.9911C35.5287 13.1451 33.0431 10.276 29.2017 10.276C25.3602 10.276 22.8678 13.1451 22.8678 16.9911V29.0723H18.1202V16.9911C18.1202 13.1451 15.6346 10.276 11.7931 10.276C9.51065 10.276 7.72574 11.1708 6.637 12.7868C5.82739 14.0137 5.40952 15.4575 5.43869 16.9272V29.07H0.666016V7.61465C1.52133 8.22236 2.27479 8.96195 2.89829 9.80584L2.97589 9.90398C4.94796 7.38183 8.06127 5.73844 11.7657 5.73844C13.5589 5.72842 15.3239 6.18461 16.8876 7.06229C18.3843 7.90686 19.6224 9.1434 20.4689 10.6389C20.4689 10.6389 20.4848 10.6709 20.494 10.6846C20.5015 10.6689 20.5098 10.6537 20.5191 10.6389C22.2195 7.67171 25.2575 5.80007 29.1925 5.80007C35.7455 5.80007 40.3196 10.7348 40.3196 17.1623'
      fill='#0C4A64'
    />
    <path
      d='M64.334 3.26858C64.334 3.76922 64.1856 4.25862 63.9074 4.67489C63.6293 5.09115 63.234 5.41559 62.7714 5.60718C62.3089 5.79877 61.8 5.84889 61.3089 5.75122C60.8179 5.65355 60.3669 5.41247 60.0129 5.05847C59.6589 4.70446 59.4178 4.25343 59.3201 3.76241C59.2225 3.27139 59.2726 2.76244 59.4642 2.2999C59.6558 1.83737 59.9802 1.44204 60.3965 1.1639C60.8127 0.885762 61.3021 0.737305 61.8028 0.737305C62.4741 0.737305 63.1179 1.00399 63.5926 1.4787C64.0673 1.95341 64.334 2.59725 64.334 3.26858Z'
      fill='#BED366'
    />
    <path
      d='M59.278 13.0301V7.50879C60.1499 8.10224 64.2995 10.7043 64.2995 14.9497L64.3132 16.7483L64.2858 29.0555C63.236 28.5718 62.3207 27.8383 61.6198 26.9191C61.6198 26.9191 61.6062 26.8825 61.597 26.8917C61.508 26.7775 61.4236 26.6634 61.3391 26.5447C60.8955 25.9216 60.5198 25.2529 60.2184 24.5498C60.1568 24.406 60.0997 24.2622 60.0449 24.1162C59.9902 23.9701 59.9377 23.8309 59.8897 23.6848C59.7397 23.2363 59.6178 22.7789 59.5245 22.3153C59.3564 21.489 59.2723 20.6478 59.2734 19.8045L59.278 13.0301Z'
      fill='#0C4A64'
    />
  </svg>,
  'MRIIcon'
);
