import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path d='M9 15.0006H3V13.0005H9V15.0006ZM3 9.00038H12V11.0005H3V9.00038ZM12 1.00004L17 6.00025H12V1.00004ZM18 10.0004V4.58619L13.414 0H2C0.897 0 0 0.898037 0 2.00008V20.0008C0 21.1039 0.897 22.0009 2 22.0009H11.079C10.397 20.8239 10 19.4598 10 18.0008C10 13.5826 13.582 10.0004 18 10.0004Z' />
      <path d='M18 17.0007C17.448 17.0007 17 16.5527 17 16.0007C17 15.4487 17.448 15.0006 18 15.0006C18.552 15.0006 19 15.4487 19 16.0007C19 16.5527 18.552 17.0007 18 17.0007ZM19 21.9999H17V17.9998H19V21.9999ZM18 11.9995C14.691 11.9995 12 14.6926 12 17.9998C12 21.3089 14.691 24 18 24C21.308 24 24 21.3089 24 17.9998C24 14.6926 21.308 11.9995 18 11.9995Z' />
    </g>
  </svg>,
  'Report File'
);
