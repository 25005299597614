import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='20'
    viewBox='0 0 16 20'
    fill='none'
  >
    <path d='M3.625 12.9165H3V14.1665H3.625C3.97 14.1665 4.25 13.8865 4.25 13.5415C4.25 13.1965 3.97 12.9165 3.625 12.9165Z' />
    <path d='M11.6783 0.833252H2.16667C1.2475 0.833252 0.5 1.58159 0.5 2.49992V17.4999C0.5 18.4191 1.2475 19.1666 2.16667 19.1666H13.8333C14.7525 19.1666 15.5 18.4191 15.5 17.4999V4.65492L11.6783 0.833252ZM3.625 15.4166H3V16.6666H1.75V11.6666H3.625C4.65833 11.6666 5.5 12.5074 5.5 13.5416C5.5 14.5758 4.65833 15.4166 3.625 15.4166ZM8.20833 15.4166H7.58333V16.6666H6.33333V11.6666H8.20833C9.24167 11.6666 10.0833 12.5074 10.0833 13.5416C10.0833 14.5758 9.24167 15.4166 8.20833 15.4166ZM14.25 12.9166H13V16.6666H11.75V12.9166H10.5V11.6666H14.25V12.9166ZM10.5 5.83325V1.66659L14.6667 5.83325H10.5Z' />
    <path d='M8.2085 12.9165H7.5835V14.1665H8.2085C8.5535 14.1665 8.8335 13.8865 8.8335 13.5415C8.8335 13.1965 8.5535 12.9165 8.2085 12.9165Z' />
  </svg>,
  'PPTIcon'
);
