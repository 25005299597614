import React from 'react';

//ALWAYS IMPORT ONLY THE COMPONENT YOU NEED FROM MATERIAL
import Typography from '@mui/material/Typography';
import styled from '@mui/styled-engine';

export type FormEntryProps = {
  title: string;
  required?: boolean;
};

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: 9px;
`;

const Title = styled('div')`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
`;

const FormEntry: React.FC<FormEntryProps> = ({ title, required, children }) => {
  return (
    <Container>
      {required ? (
        <Title>
          <span>
            <Typography display='inline' variant='body1' fontWeight='bold'>
              {title}{' '}
            </Typography>
            <Typography
              display='inline'
              variant='body1'
              fontWeight='bold'
              color='primary'
            >
              *
            </Typography>
          </span>
        </Title>
      ) : (
        <Title>{title}</Title>
      )}
      {children}
    </Container>
  );
};

FormEntry.displayName = '@cherre-frontend/FormEntry';

export default FormEntry;
