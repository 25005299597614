import React, { useMemo } from 'react';
import FilterChip from './FilterChip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import styled from '@mui/material/styles/styled';

const MenuItemStyled = styled(MenuItem)`
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

export type MultiFilterOption<T = any> = {
  label: string;
  value: T;
};

export type MultiFilterProps<T> = {
  options: MultiFilterOption<T>[];
  selected: T[];
  onChange: (selected: T[]) => void;
  label: string;
  disabled?: boolean;
};

const MultiFilter = <T,>({
  options,
  selected,
  onChange,
  label,
  disabled,
}: MultiFilterProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleStatusFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusFilterClose = () => {
    setAnchorEl(null);
  };

  const handleStatusFilterChange = (option: T) => () => {
    const newSelected = selected.includes(option)
      ? selected.filter((s) => s !== option)
      : [...selected, option];
    onChange(newSelected);
  };

  const filterLabel = useMemo(() => {
    if (!selected.length) {
      return label;
    }
    const firstOptionLabel =
      options.find((option) => option.value === selected[0])?.label || label;
    if (selected.length === 1) {
      return firstOptionLabel;
    }
    return `${firstOptionLabel} +${selected.length - 1}`;
  }, [selected, options]);

  return (
    <>
      <FilterChip
        label={filterLabel}
        onClick={handleStatusFilterClick}
        active={Boolean(selected.length)}
        onDelete={() => onChange([])}
        disabled={options.length === 0 || disabled}
      />
      <Menu
        id='more-items-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleStatusFilterClose}
        data-testid='more-items-menu'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MenuItemStyled
            key={option.label}
            style={{ minWidth: '200px', gap: '6px' }}
            onClick={handleStatusFilterChange(option.value)}
          >
            <Checkbox checked={selected.includes(option.value)} />
            {option.label}
          </MenuItemStyled>
        ))}
      </Menu>
    </>
  );
};

MultiFilter.displayName = '@cherre-frontend/ui/MultiFilter';

export default MultiFilter;
