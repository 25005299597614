import { Box, TextField, StandardTextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export interface ControlledInputProps<
  T extends FieldValues,
  TName extends FieldPath<T>
> extends StandardTextFieldProps {
  control: Control<T>;
  name: TName;
}

interface WithForwardReferenceType<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
> extends React.FC<ControlledInputProps<T, TName>> {
  <T extends FieldValues, TName extends FieldPath<T>>(
    props: ControlledInputProps<T, TName>
  ): ReturnType<React.FC<ControlledInputProps<T, TName>>>;
}

export const ControlledInput: WithForwardReferenceType = forwardRef(
  ({ control, name, label, ...inputProps }, _reference) => {
    const id = inputProps.id || name;

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { ref: _reference, ...field }, fieldState }) => {
          return (
            <>
              {label && (
                <Box
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    display: 'block',
                    marginBottom: '5px',
                    color: 'black',
                  }}
                  htmlFor={id}
                  component='label'
                >
                  {label}
                  {inputProps.required && (
                    <Box component='span' color='#E53935'>
                      {inputProps.required && '*'}
                    </Box>
                  )}
                </Box>
              )}

              <TextField
                error={!!fieldState.error?.message}
                variant='outlined'
                size='small'
                id={id}
                {...field}
                {...inputProps}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  ...inputProps.sx,
                }}
                InputProps={{
                  sx: {
                    borderRadius: '6px',
                    '& .MuiInputBase-input': {
                      borderRadius: '6px',
                      borderColor: '#9E9E9E',
                      paddingInline: 0,
                      padding: '8px',
                      maxHeight: '34px',
                      height: '18px',
                      '&:disabled': {
                        backgroundColor: '#F5F5F5',
                      },
                    },
                    ...inputProps.InputProps?.sx,
                  },
                }}
                helperText={fieldState.error?.message || inputProps.helperText}
              />
            </>
          );
        }}
      />
    );
  }
) as WithForwardReferenceType;

ControlledInput.displayName = 'ControlledInput';
