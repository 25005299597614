import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='34'
    height='34'
    viewBox='0 0 34 34'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.729 0.5C22.5935 0.5 28.958 6.8645 28.958 14.7275C28.958 17.507 28.16 20.1605 26.6825 22.4405L32.621 28.379C33.7895 29.5475 33.7895 31.4495 32.621 32.6195C31.4495 33.794 29.552 33.7955 28.376 32.618L22.439 26.681C20.1605 28.157 17.507 28.9565 14.729 28.9565C6.8645 28.9565 0.5 22.589 0.5 14.7275C0.5 6.863 6.866 0.5 14.729 0.5ZM14.729 3.5C8.522 3.5 3.5 8.522 3.5 14.7275C3.5 20.9345 8.5265 25.9565 14.729 25.9565C17.7275 25.9565 20.5475 24.7895 22.667 22.667C24.7895 20.546 25.958 17.7275 25.958 14.7275C25.958 8.5205 20.9315 3.5 14.729 3.5ZM14.7493 7.9994L22.9993 13.9994H19.9993V19.9994H16.9993V16.9994H12.4993V19.9994H9.49925V13.9994H6.49925L14.7493 7.9994Z'
    />
  </svg>,
  'SearchAsset'
);
