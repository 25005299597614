import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.5 12.5H2.5V10.8333H7.5V12.5ZM2.5 7.5H10V9.16667H2.5V7.5ZM10 0.833333L14.1667 5H10V0.833333ZM15 8.33333V3.82167L11.1783 0H1.66667C0.7475 0 0 0.748333 0 1.66667V16.6667C0 17.5858 0.7475 18.3333 1.66667 18.3333H9.2325C8.66333 17.3525 8.33333 16.2158 8.33333 15C8.33333 11.3183 11.3175 8.33333 15 8.33333Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 16.6667C14.0783 16.6667 13.3333 15.92 13.3333 15C13.3333 14.08 14.0783 13.3333 15 13.3333C15.92 13.3333 16.6667 14.08 16.6667 15C16.6667 15.92 15.92 16.6667 15 16.6667ZM20.0017 15.8333V14.1667H19.0833C18.9742 13.635 18.7625 13.1425 18.4725 12.705L19.1258 12.0517L17.9475 10.8733L17.2933 11.5267C16.8575 11.2383 16.365 11.025 15.8342 10.9183V10H14.1675V10.9183C13.6358 11.025 13.1442 11.2383 12.7067 11.5275L12.0542 10.875L10.8758 12.0533L11.5283 12.7067C11.2383 13.1425 11.0258 13.6358 10.9183 14.1667H10V15.8333H10.9183C11.0258 16.365 11.2383 16.8575 11.5283 17.295L10.8758 17.9483L12.055 19.1258L12.7067 18.4725C13.1442 18.7625 13.6367 18.975 14.1683 19.0825V20.0008H15.835V19.0817C16.3658 18.9742 16.8592 18.7617 17.2958 18.4717L17.9492 19.1242L19.1267 17.9458L18.4733 17.2933C18.7633 16.8567 18.9742 16.3642 19.0833 15.8333H20.0017Z'
    />
  </svg>,
  'Group'
);
