import React from 'react';
import { ExtendAppContext, ExtendAppContextProps } from '@cherre-frontend/core';
import { DataFetcher, DataFetcherProps } from '@cherre-frontend/data-fetching';

//ALWAYS IMPORT ONLY THE COMPONENT YOU NEED FROM MATERIAL
import CssBaseline from '@mui/material/CssBaseline';
import styled from '@mui/styled-engine';

const Container = styled(ExtendAppContext)`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export type PageContainerProps = ExtendAppContextProps & DataFetcherProps;

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <>
      <CssBaseline />
      <Container {...props}>
        <DataFetcher {...props}>{children}</DataFetcher>
      </Container>
    </>
  );
};

PageContainer.displayName = '@cherre-frontend/PageContainer';
