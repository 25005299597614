import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19 9C20.103 9 21 9.898 21 11V20C21 21.103 20.103 22 19 22H12C10.897 22 10 21.103 10 20V11C10 9.898 10.897 9 12 9H19ZM12 2C13.103 2 14 2.898 14 4V7H10C8.897 7 8 7.898 8 9V15H5C3.897 15 3 14.103 3 13V4C3 2.898 3.897 2 5 2H12Z'
      fill='currentColor'
    />
  </svg>,
  'Copy'
);
