import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.416 0.57586C9.676 0.38786 10.011 0.33786 10.316 0.43886L14.316 1.77186C14.725 1.90786 15 2.29086 15 2.72086V12.6229C15.401 12.6229 17.044 13.5219 17.447 13.7239C17.786 13.8929 18 14.2389 18 14.6179V22.9999H15V21.4999C15 20.6719 14.328 19.9999 13.5 19.9999C12.672 19.9999 12 20.6719 12 21.4999V22.9999H10C9.448 22.9999 9 22.5519 9 21.9999V12.9999H13V10.9999H9V8.99986H13V6.99986H9V4.99986H13V2.99986H9V1.38686C9 1.06586 9.154 0.76386 9.416 0.57586ZM17.4743 6.76746C17.7693 6.58546 18.1373 6.56846 18.4473 6.72346L22.4473 8.72346C22.7863 8.89246 23.0003 9.23946 23.0003 9.61846V21.9995C23.0003 22.5525 22.5523 22.9995 22.0003 22.9995H20.0003V12.9995L17.0003 11.1995V7.61846C17.0003 7.27146 17.1793 6.94946 17.4743 6.76746ZM16 14.9999H11V16.9999H16V14.9999ZM5 11.9999H3V9.99986H5V11.9999ZM5 15.9999H3V13.9999H5V15.9999ZM5 19.9999H3V17.9999H5V19.9999ZM6.585 5.57586C6.324 5.38786 5.989 5.33786 5.684 5.43886L1.684 6.77186C1.275 6.90786 1 7.29086 1 7.72086V21.9999C1 22.5519 1.448 22.9999 2 22.9999H6C6.552 22.9999 7 22.5519 7 21.9999V6.38686C7 6.06586 6.846 5.76386 6.585 5.57586Z'
      fill='currentColor'
    />
  </svg>,
  'City'
);
