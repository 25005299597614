import React from 'react';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useCherreEvent } from '@cherre-frontend/core';
import IconButton, { IconButtonProps } from '../components/IconButton';
import { useIsSuspended } from '@cherre-frontend/data-fetching';

type Props = {
  iconButtonProps?: IconButtonProps;
  onClick?: () => void;
};

const ActionsMenu: React.FC<Props> = ({
  iconButtonProps,
  onClick,
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMoreClick = useCherreEvent(
    'handleMoreClick',
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick();
      }
      setAnchorEl(event.currentTarget);
    }
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSuspended = useIsSuspended();
  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton
        size='small'
        color='primary'
        onClick={handleMoreClick}
        data-testid='more-items-menu-button'
        style={
          iconButtonProps
            ? iconButtonProps.style ?? { paddingLeft: 0 }
            : { paddingLeft: 0 }
        }
        {...iconButtonProps}
        disabled={isSuspended}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        data-testid='more-items-menu'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default ActionsMenu;
