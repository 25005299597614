import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '.';
import { default as styled } from '@mui/material/styles/styled';

const DialogStyled = styled(Dialog)`
  & .MuiPaper-root {
    width: 520px;
  }
  & .MuiDialogTitle-root {
    padding: 20px 20px 6px;
  }
  & .MuiDialogContent-root {
    padding: 0 20px 0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  & .MuiDialogActions-root {
    padding: 20px;
  }
`;

export type ConfirmationModalProps = {
  open: boolean;
  title?: React.ReactNode;
  content?: React.ReactNode;
  handleCancel?: () => void;
  handleConfirm?: () => void;
  hideCancel?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  title,
  content,
  handleCancel,
  handleConfirm,
  cancelLabel = 'Cancel',
  hideCancel,
  confirmLabel = 'OK',
}) => {
  return (
    <DialogStyled open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button autoFocus onClick={handleCancel}>
            {cancelLabel}
          </Button>
        )}
        <Button onClick={handleConfirm} variant='contained'>
          {confirmLabel}
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};
