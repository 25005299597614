import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='13'
    height='7'
    viewBox='0 0 13 7'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.798 4.238V3.076H4.564V4.238H0.798ZM10.4694 0.794L12.4154 3.664L10.4694 6.562H9.25138L11.0854 3.664L9.25138 0.794H10.4694ZM11.3654 3.16V4.182H6.29738V3.16H11.3654Z'
      fill='currentColor'
    />
  </svg>,
  'DashedArrow'
);
