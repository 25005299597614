import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.5047 12.7542L18.3333 15.5283L17.4228 16.4568L16.1544 15.2142L16.1548 18.6109H14.8543L14.854 15.2133L13.5847 16.4569L12.6744 15.5282L15.5047 12.7542ZM11.1697 0.833374L16.3715 6.0352L16.3726 11.3239C16.0921 11.2669 15.8018 11.237 15.5046 11.237C13.1105 11.237 11.1697 13.1778 11.1697 15.5719C11.1697 16.5482 11.4925 17.4491 12.0371 18.1738L4.22527 18.1728C3.31495 18.1728 2.57052 17.4618 2.50473 16.5678L2.5 16.4388L2.50867 2.56731C2.50867 1.657 3.21172 0.904666 4.10508 0.838153L4.23394 0.833374H11.1697ZM10.3027 2.13383V6.90217H15.0711L10.3027 2.13383Z'
      fill='currentColor'
    />
  </svg>,
  'UploadFile'
);
