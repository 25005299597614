import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import React, { forwardRef } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export type ControlledSelectOption = {
  value: string | number;
  label: string;
};

export interface ControlledSelectProps<
  T extends FieldValues,
  TName extends FieldPath<T>
> extends SelectProps {
  control: Control<T>;
  name: TName;
  size?: 'small' | 'medium';
  helperText?: string;
  options: ControlledSelectOption[];
}

interface WithForwardReferenceType<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
> extends React.FC<ControlledSelectProps<T, TName>> {
  <T extends FieldValues, TName extends FieldPath<T>>(
    props: ControlledSelectProps<T, TName>
  ): ReturnType<React.FC<ControlledSelectProps<T, TName>>>;
}

export const ControlledSelect: WithForwardReferenceType = forwardRef(
  (
    { control, name, options, helperText, label, ...inputProps },
    _reference
  ) => {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { ref: _reference, ...field }, fieldState }) => {
          const formHelper = fieldState.error?.message || helperText;
          const hasError = !!fieldState.error?.message;
          const id = inputProps.id || name;
          const labelId = `select-${id}-label`;

          return (
            <FormControl
              error={hasError}
              size={inputProps.size}
              fullWidth={inputProps.fullWidth}
            >
              {label && (
                <Box
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    display: 'block',
                    marginBottom: '5px',
                    color: 'black',
                  }}
                  htmlFor={id}
                  component='label'
                >
                  {label}
                  {inputProps.required && (
                    <Box component='span' color='#E53935'>
                      {inputProps.required && '*'}
                    </Box>
                  )}
                </Box>
              )}
              <Select
                labelId={labelId}
                id={id}
                variant='outlined'
                error={hasError}
                {...field}
                {...inputProps}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {formHelper && <FormHelperText>{formHelper}</FormHelperText>}
            </FormControl>
          );
        }}
      />
    );
  }
) as WithForwardReferenceType;

ControlledSelect.displayName = 'ControlledSelectInput';
