import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_17879_2264)'>
      <path
        d='M10 2L12.3511 6.76393L17.6085 7.52786L13.8042 11.2361L14.7023 16.4721L10 14L5.29772 16.4721L6.19577 11.2361L2.39155 7.52786L7.64886 6.76393L10 2Z'
        fill='#212121'
        stroke='#212121'
        stroke-width='2'
      />
    </g>
    <defs>
      <clipPath id='clip0_17879_2264'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'StarIcon'
);
