import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    width='16'
    height='16'
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.1456 6.49935V7.07325C10.4773 7.13991 10.7851 7.27271 11.0575 7.45342L11.4663 7.04513L12.2027 7.78151L11.7944 8.1898C11.9756 8.46321 12.1084 8.77099 12.1761 9.10324H12.75V10.1448H12.1761C12.1084 10.4765 11.9761 10.7843 11.7954 11.0572L12.2032 11.4645L11.4673 12.2014L11.059 11.7936C10.7861 11.9748 10.4778 12.1076 10.1461 12.1748V12.7492H9.10455V12.1753C8.77281 12.1081 8.46451 11.9753 8.1911 11.7941L7.78385 12.2024L7.04695 11.4665L7.45472 11.0582C7.27349 10.7848 7.14121 10.4771 7.07351 10.1448H6.49961V9.10324H7.07351C7.14121 8.77151 7.27349 8.46321 7.45472 8.19032L7.04695 7.78255L7.78333 7.04617L8.1911 7.45394C8.46451 7.27271 8.77229 7.13991 9.10403 7.07325V6.49935H10.1456ZM0.25 8.04502C1.19209 8.66423 2.76276 9.10324 4.93701 9.10324C5.12918 9.10324 5.31301 9.09752 5.49581 9.09127C5.47341 9.26625 5.45779 9.44279 5.45779 9.62402C5.45779 9.97711 5.50622 10.3177 5.58903 10.6448C5.37238 10.6572 5.15522 10.6656 4.93701 10.6656C2.60809 10.6656 0.25 9.95003 0.25 8.58247V8.04502ZM9.62428 8.58247C9.0483 8.58247 8.58273 9.04908 8.58273 9.62402C8.58273 10.199 9.0483 10.6656 9.62428 10.6656C10.1992 10.6656 10.6658 10.199 10.6658 9.62402C10.6658 9.04908 10.1992 8.58247 9.62428 8.58247ZM0.25026 5.44113C1.19235 6.06033 2.76302 6.49935 4.93727 6.49935C5.73198 6.49935 6.44232 6.43842 7.07507 6.33374C6.50534 6.77536 6.05226 7.35916 5.77312 8.03565C5.50909 8.05075 5.23464 8.06169 4.93727 8.06169C1.46039 8.06169 0.287496 6.93642 0.251138 6.51899L0.25026 6.49935V5.44113ZM9.62428 2.83723V3.89545C9.62428 4.29593 8.47024 5.45779 4.93727 5.45779C1.46039 5.45779 0.287496 4.33252 0.251138 3.91509L0.25026 3.89545V2.83723C1.19235 3.45644 2.76302 3.89545 4.93727 3.89545C7.11152 3.89545 8.68219 3.45644 9.62428 2.83723ZM4.93727 0.25C6.85738 0.25 8.79312 0.737449 9.41545 1.67433C8.94987 2.17324 7.64272 2.8539 4.93727 2.8539C2.23182 2.8539 0.924669 2.17324 0.459093 1.67433C1.08142 0.737449 3.01716 0.25 4.93727 0.25Z'
      fill='currentColor'
    />
  </svg>,
  'TogglesIcon'
);
