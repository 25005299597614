import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 1.66406C9.27987 1.66406 8.58403 1.76545 7.91667 1.93837V3.37309C8.57501 3.16615 9.27431 3.05295 10 3.05295C13.8299 3.05295 16.9445 6.16823 16.9445 9.9974C16.9445 13.8266 13.8299 16.9418 10 16.9418C6.17084 16.9418 3.05556 13.8266 3.05556 9.9974C3.05556 8.06129 3.85556 6.31267 5.13889 5.05365V7.21962H6.52778V3.05295H2.36112V4.44184H3.80487C2.47917 5.91753 1.66667 7.86198 1.66667 9.9974C1.66667 14.5925 5.40556 18.3307 10 18.3307C14.5951 18.3307 18.3333 14.5925 18.3333 9.9974C18.3333 5.40226 14.5951 1.66406 10 1.66406Z'
      fill='#757575'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.3056 5.13281V10.2821L12.2869 13.2634L13.2688 12.2807L10.6945 9.70642V5.13281H9.3056Z'
      fill='#757575'
    />
  </svg>,

  'CycleTimeIcon'
);
