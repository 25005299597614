import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.63765 3.81883C6.11899 3.81883 5.69804 3.39788 5.69804 2.87922C5.69804 2.36055 6.11899 1.93961 6.63765 1.93961C7.15632 1.93961 7.57726 2.36055 7.57726 2.87922C7.57726 3.39788 7.15632 3.81883 6.63765 3.81883ZM3.81883 3.81883C3.30016 3.81883 2.87922 3.39788 2.87922 2.87922C2.87922 2.36055 3.30016 1.93961 3.81883 1.93961C4.33749 1.93961 4.75844 2.36055 4.75844 2.87922C4.75844 3.39788 4.33749 3.81883 3.81883 3.81883ZM19.7922 2.87922C19.7922 1.84283 18.9493 1 17.913 1H2.87922C1.84283 1 1 1.84283 1 2.87922V4.76031H19.7922V2.87922Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.63765 9.45957H2.87922V7.58035H6.63765V9.45957ZM6.63765 15.0972H2.87922V13.218H6.63765V15.0972ZM2.87922 10.3992H5.69804V12.2784H2.87922V10.3992ZM8.51687 7.58035H12.2753V9.45957H8.51687V7.58035ZM7.57726 10.3992H12.6587C13.6462 9.80629 14.7982 9.45957 16.0337 9.45957H14.1545V7.58035H17.913V9.45957H16.0337C17.4319 9.45957 18.7267 9.89931 19.7922 10.6435V5.7002H1V16.034C1 17.0704 1.84283 17.9132 2.87922 17.9132H9.73178C9.5542 17.3185 9.45648 16.6889 9.45648 16.0368C9.45648 15.7174 9.48749 15.4045 9.53165 15.0972H8.51687V13.218H10.0982C10.2542 12.8891 10.4337 12.5744 10.6404 12.2784H7.57726V10.3992Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.0337 18.8557C14.4796 18.8557 13.2149 17.591 13.2149 16.0369C13.2149 14.4828 14.4796 13.2181 16.0337 13.2181C17.5879 13.2181 18.8526 14.4828 18.8526 16.0369C18.8526 17.591 17.5879 18.8557 16.0337 18.8557ZM19.9491 18.6237C20.4414 17.8814 20.7318 16.9925 20.7318 16.0369C20.7318 13.4464 18.6242 11.3389 16.0337 11.3389C13.4432 11.3389 11.3357 13.4464 11.3357 16.0369C11.3357 18.6274 13.4432 20.735 16.0337 20.735C16.9903 20.735 17.8772 20.4446 18.6205 19.9523L21.6714 23.0032L23 21.6746L19.9491 18.6237Z'
      fill='currentColor'
    />
  </svg>,
  'Report Window'
);
