import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Icons'>
      <g id='Fill-415-+-Fill-416'>
        <path
          id='Fill-415'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.875 13.125H2.625V11.375H7.875V13.125ZM2.625 7.875H10.5V9.625H2.625V7.875ZM10.5 0.875L14.875 5.25H10.5V0.875ZM15.75 8.75V4.01275L11.7373 0H1.75C0.784875 0 0 0.78575 0 1.75V17.5C0 18.4651 0.784875 19.25 1.75 19.25H9.69413C9.09738 18.2201 8.75 17.0266 8.75 15.75C8.75 11.8843 11.8843 8.75 15.75 8.75Z'
          fill='#DF2467'
        />
        <path
          id='Fill-416'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.75 14.8749C15.267 14.8749 14.875 14.4829 14.875 13.9999C14.875 13.5169 15.267 13.1249 15.75 13.1249C16.233 13.1249 16.625 13.5169 16.625 13.9999C16.625 14.4829 16.233 14.8749 15.75 14.8749ZM16.625 19.249H14.875V15.749H16.625V19.249ZM15.75 10.499C12.8546 10.499 10.5 12.8554 10.5 15.749C10.5 18.6444 12.8546 20.999 15.75 20.999C18.6445 20.999 21 18.6444 21 15.749C21 12.8554 18.6445 10.499 15.75 10.499Z'
          fill='#DF2467'
        />
      </g>
    </g>
  </svg>,
  'ReportInfoIcon'
);
