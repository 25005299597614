import { createSvgIcon } from '@mui/material';
import React from 'react';

export default createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.25 16.75V6.66666H18.4166V16.75H4.66663V18.5833H18.4166C19.4277 18.5833 20.25 17.7611 20.25 16.75Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.1666 9.41666V19.5H7.41663V21.3333H21.1666C22.1777 21.3333 23 20.5111 23 19.5V9.41666H21.1666Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5 14V8.5H1V14C1 15.0111 1.82225 15.8333 2.83333 15.8333H15.6667C16.6778 15.8333 17.5 15.0111 17.5 14Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 5.74736C5.994 5.74736 5.58333 5.33669 5.58333 4.83069C5.58333 4.32469 5.994 3.91402 6.5 3.91402C7.006 3.91402 7.41667 4.32469 7.41667 4.83069C7.41667 5.33669 7.006 5.74736 6.5 5.74736ZM3.75 5.74736C3.244 5.74736 2.83333 5.33669 2.83333 4.83069C2.83333 4.32469 3.244 3.91402 3.75 3.91402C4.256 3.91402 4.66667 4.32469 4.66667 4.83069C4.66667 5.33669 4.256 5.74736 3.75 5.74736ZM17.5 4.83344C17.5 3.82236 16.6778 3.00011 15.6667 3.00011H2.83333C1.82225 3.00011 1 3.82236 1 4.83344V6.66677H17.5V4.83344Z'
      fill='currentColor'
    />
  </svg>,
  'Multi Apps'
);
