import React from 'react';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export default createSvgIcon(
  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path d='M21.4737 0.737739V3.43617L17.3141 0L15.528 1.47548L19.6876 4.91165H16.4211V6.99861H24V0.737739H21.4737Z' />
      <path d='M7.57895 18.4768V16.3899H0V22.6508H2.52632V19.9523L6.6859 23.3885L8.472 21.913L4.31242 18.4768H7.57895Z' />
      <path d='M3.7895 15.3465H7.57897V9.0856C7.57897 7.93568 8.71203 6.99864 10.1053 6.99864H15.1579V3.86821C15.1579 2.71829 14.0249 1.78125 12.6316 1.78125H3.7895C2.39624 1.78125 1.26318 2.71829 1.26318 3.86821V13.2595C1.26318 14.4105 2.39624 15.3465 3.7895 15.3465Z' />
      <path d='M21.4737 16.3898H12.6316V15.3463H21.4737V16.3898ZM21.4737 19.5202H12.6316V18.4768H21.4737V19.5202ZM12.6316 12.2159H17.6842V13.2594H12.6316V12.2159ZM21.4737 9.08545H12.6316C11.2383 9.08545 10.1053 10.0225 10.1053 11.1724V20.5637C10.1053 21.7147 11.2383 22.6507 12.6316 22.6507H21.4737C22.867 22.6507 24 21.7147 24 20.5637V11.1724C24 10.0225 22.867 9.08545 21.4737 9.08545Z' />
    </g>
  </svg>,
  'SubmissionIcon'
);
