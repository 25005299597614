export { default as Card, CardProps } from '@mui/material/Card';
export { default as CardMedia, CardMediaProps } from '@mui/material/CardMedia';
export {
  default as CardHeader,
  CardHeaderProps,
} from '@mui/material/CardHeader';
export {
  default as CardContent,
  CardContentProps,
} from '@mui/material/CardContent';
export {
  default as CardActions,
  CardActionsProps,
} from '@mui/material/CardActions';
export {
  default as CardActionArea,
  CardActionAreaProps,
} from '@mui/material/CardActionArea';
