import React from 'react';

export const DotSeparator: React.FC = ({ children }) => {
  const childrenArray = React.Children.map(children, (child) =>
    child ? [' • ', child] : [child]
  )
    ?.flat()
    .slice(1);
  return <>{childrenArray}</>;
};
